import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";
import { faLuggageCart } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateDisplay, TimeDisplay } from "components/Shared/DateDisplay";
import IncServices from "components/Shared/IncServices";
import React, { useState, CSSProperties } from "react";
import { AvailableFareConnectionModel, AvailableFareModel } from "WebApiClient";
import { AllotmentModal } from "./Fare.AllotmentInfo";
import moment from "moment";
import _ from "lodash";
import { SabreIconsEmoji } from "./IncludedServiceIcons/SabreIcons";
import Tooltip from "@mui/material/Tooltip";
import { State } from 'rootExports/rootReducer';
import { useSelector } from "react-redux";
moment().format();

interface OwnProps {
  Data: AvailableFareConnectionModel;
  SelectedConnection: any;
  GetSelectedConnectionIndexByLegIndex: any;
  LegIndex: number;
  ConnectionIndex: number;
  SelectConnection: (legIndex: number, connectionIndex: number) => void;
  IsWebFare: boolean;
  connectionAppliesToFilter: boolean;
  Fare: AvailableFareModel;
  connection: any;
  resultIsPresentAllIncl?: boolean;
  baggageIsPresentAll?: boolean;
  cabinIsPresentAll?: boolean;
  isCabinVisibleInTable?: boolean;
  doesCodeShareHaveValue?: boolean;
  radio: boolean;
  showSelected: boolean;
}

const FareLegConnectionTableRow: React.FC<OwnProps> = (props) => {
  const [AllotmentModalOpen, setAllotmentModalOpen] = useState(false);
  const iconDataDefaultHeight: number = useSelector((state: State) => state.Session.FrontendSettings.iconDataSettings[0].defaultHeight)
  const isWebFare = props.IsWebFare;

  function SegmentHasAllotment() {
    let result = false;
    props.Data.segments.forEach((seg) => {
      if (seg.allotmentInformation) {
        result = true;
      }
    });
    return result;
  }

  function ToggleAllotmentModal() {
    setAllotmentModalOpen(!AllotmentModalOpen);
  }

  function OnConnectionSelection() {
    if (props.connectionAppliesToFilter) {
      props.SelectConnection(props.LegIndex, props.ConnectionIndex);
      props.GetSelectedConnectionIndexByLegIndex(props.ConnectionIndex);
      props.GetSelectedConnectionIndexByLegIndex(props.LegIndex);
    }
  }

  const padNumberWithZeros = (value: string) => {
    const stringValue = value.length;
    const noOfZero = 4 - stringValue;
    const paddingZeros = "0".repeat(Math.max(0, noOfZero));
    return paddingZeros + value;
  };

  function capitalizeFirstAndUpperCaseRest(str: string) {
    // Convert the entire string to uppercase
    const upperStr = str.toUpperCase();
    // Capitalize the first letter and concatenate with the rest of the string
    return upperStr.charAt(0) + upperStr.slice(1).toLowerCase();
  }
  //mealcode make array
  const mealCodeArray = _.split(props.Data.mealCode, ",");
  const iconData = useSelector((state: State) => state.Session.FrontendSettings.iconDataSettings);

  // Call SabreIconsEmoji and store all icons
  const allIcons: any = SabreIconsEmoji(iconData);

  const getRightIcon = (code: string) => {
    // Assuming SabreIconsEmoji() returns the JSON object
    const data: string[] = Object.keys(allIcons);
    if (data.includes(code)) {
      // If code is found in data, return the code
      return code;
    } else {
      // If code is not found, return the first character of the code
      const firstCharacter = code.charAt(0);
      return firstCharacter;
    }
  };

  return (
    <React.Fragment>
      {props.showSelected ? (
        props.SelectedConnection === props.ConnectionIndex ? (
          <tr
            className={props.connectionAppliesToFilter ? "" : "table-inactive "}
          >
            <td
              className="text-truncate fareTabletrtdRouting"
              onClick={(e) => {
                e.stopPropagation();
                OnConnectionSelection();
              }}
            >
              {" "}
              {/* ROUTING  */}
              {props.Data.segments!.map((segment, index) => (
                <span key={"ROUTING" + index}>
                  <span>{segment.departureAirport!.iata}</span>-
                  <span>{segment.arrivalAirport!.iata}</span>
                  <br />
                </span>
              ))}
            </td>
            {(!props.cabinIsPresentAll || props.isCabinVisibleInTable) && (
              <td
                className="text-truncate fareTabletrtdCabin"
                onClick={(e) => {
                  e.stopPropagation();
                  OnConnectionSelection();
                }}
              >
                {" "}
                {/* CabinClass  */}
                {props.Data.segments!.map((segment, index) => (
                  <span key={"CabinClass" + index}>
                    <span
                      style={{
                        fontWeight: `${segment.cabinClassName == "Economy" ? 400 : 600
                          }`,
                      }}
                    >
                      {segment.cabinClassName == "PremiumEconomy"
                        ? "Premium"
                        : segment.cabinClassName}
                    </span>
                    <br />
                  </span>
                ))}
              </td>
            )}
            <td className="text-truncate fareTabletrtdFlight" onClick={(e) => {
              e.stopPropagation();
              OnConnectionSelection();
            }}>
              {" "}
              {/* FLIGHT  */}
              {props.Data.segments!.map((segment, index) => (
                <span key={"FLIGHT" + index}>
                  <span>
                    {segment.carrier.code}
                    {/* {segment.flightNumber} */}
                    {padNumberWithZeros(segment.flightNumber)}
                    &nbsp;by&nbsp;
                    {segment?.codeShare?.code && segment?.codeShare?.hint?.length
                      ? segment?.codeShare?.hint
                      : segment?.carrier?.hint}
                  </span>
                  <br />
                </span>
              ))}
            </td>
            <td className="text-truncate fareTabletrtdDate" onClick={(e) => {
              e.stopPropagation();
              OnConnectionSelection();
            }}>
              {" "}
              {/* DATES  */}
              {props.Data.segments!.map((segment, index) => {
                // Parse the date using Moment.js
                const dateTimeObject = moment(segment.departureDate);
                // Check the day of the weeks
                const dayOfWeek = dateTimeObject.format("dddd");
                return (
                  <>
                    <span data-toggle="tooltip" title={dayOfWeek}>
                      <span>
                        <DateDisplay date={segment.departureDate} />
                        {/* <DateFormat dates={segment.departureDate} /> */}
                      </span>
                      <br />
                    </span>
                  </>
                );
              })}
            </td>
            <td className="text-truncate fareTabletrtdTime" onClick={(e) => {
              e.stopPropagation();
              OnConnectionSelection();
            }}>
              {" "}
              {/* TIMES  */}
              {props.Data.segments!.map((segment, index) => (
                <span key={"props.Data.segments4" + index}>
                  <span>
                    <span>
                      <TimeDisplay date={segment.departureDate} /> -{" "}
                    </span>
                    <span>
                      <TimeDisplay date={segment.arrivalDate} />
                      <span className="nextday">
                        {" "}
                        <sup>{segment.dayIndicator}</sup>
                      </span>
                    </span>
                  </span>
                  <br />
                </span>
              ))}
            </td>
            <td className="text-truncate fareTabletrtdType" onClick={(e) => {
              e.stopPropagation();
              OnConnectionSelection();
            }}>
              {" "}
              {/* TYPE  */}
              {props.Data.segments!.map((segment, index) =>
                segment.equipmentCode == segment.equipmentName ? (
                  <span key={"props.Data.segments2" + index}>
                    <span>{segment.equipmentCode}</span>
                    <br />
                  </span>
                ) : (
                  <span key={"props.Data.segments2" + index}>
                    {/* <span>{segment.equipmentCode}</span> */}
                    <span style={{ textTransform: 'capitalize' }}> {capitalizeFirstAndUpperCaseRest(segment.equipmentName)}</span>
                    <br />
                  </span>
                )
              )}
            </td>
            {!props.baggageIsPresentAll && (
              <td className="text-truncate fareTabletrtdBaggage" onClick={(e) => {
                e.stopPropagation();
                OnConnectionSelection();
              }}>
                {" "}
                {/* BAGGAGE */}
                {props.Data.segments!.map((segment, index) => (
                  <span key={"props.Data.segments6" + index}>
                    <span>
                      {!segment.freeBaggageAllowance ? (
                        <>
                          <FontAwesomeIcon
                            style={{
                              opacity: !segment.freeBaggageAllowance ? 0.3 : 1,
                            }}
                            title="This fare has no free baggage allowance."
                            icon={faLuggageCart}
                          />
                          <FontAwesomeIcon
                            style={
                              {
                                "--fa-secondary-opacity": "0.7",
                                "--fa-primary-color": "black",
                                color: `${!segment.freeBaggageAllowance ? "red" : ""
                                  }`,
                              } as CSSProperties
                            }
                            icon={
                              !segment.freeBaggageAllowance
                                ? faExclamationTriangle
                                : faCheckCircle
                            }
                            transform="shrink-7 down-4 left-9"
                          />
                        </>
                      ) : (
                        segment.freeBaggageAllowance
                      )}
                    </span>
                    <br />
                  </span>
                ))}
              </td>
            )}
            {!props.resultIsPresentAllIncl && (
              <td className="fareTabletrtdServices" onClick={(e) => {
                e.stopPropagation();
                OnConnectionSelection();
              }}>
                {" "}
                {/* INCLUDEDSERVICES  */}
                <span className="ancillaries text-s">
                  {props.Data.segments!.map((segment, index) => (
                    <span key={"props.Data.segments7" + segment.index + index}>
                      <span>
                        {segment.includedServices && (
                          <React.Fragment>
                            {segment.includedServices.map(
                              (mainGroup, mainGroupIndex) => (
                                <IncServices data={mainGroup} toShow={true} iconDataDefaultHeight={iconDataDefaultHeight} fullToolTip={false} includedServices={segment.includedServices} />
                              )
                            )}
                          </React.Fragment>
                        )}
                      </span>
                      <br />
                    </span>
                  ))}
                </span>
              </td>
            )}
            {
              props?.Data?.mealCode?.length > 0 && (
                <td className="fareTabletrtdMeal" onClick={(e) => {
                  e.stopPropagation();
                  OnConnectionSelection();
                }}>
                  {mealCodeArray?.map((iconD: any, iMeal: number) => (
                    <>

                      {/* {SabreIconsEmoji()?.[iconD]?.icon} */}
                      <img
                        style={{ marginTop: "2px" }}
                        src={`${allIcons?.[iconD]?.icon}`}
                        height={`${iconDataDefaultHeight}px`}
                        alt={`${iconD}`}
                      />
                    </>
                  ))}
                </td>
              )
            }
            {SegmentHasAllotment() && (
              <td className="text-center align-middle clickme" onClick={(e) => {
                e.stopPropagation();
                OnConnectionSelection();
              }}>
                {" "}
                {/* DETAILS  */}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    OnConnectionSelection();
                  }}
                  className="btn btn-sm"
                >
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </td>
            )}
          </tr>
        ) : null
      ) : (
        <tr
          className={props.connectionAppliesToFilter ? "" : "table-inactive "}
        >
          {props.radio && (
            <td style={{ width: "1%" }} className="text-center" onClick={(e) => {
              e.stopPropagation();
              OnConnectionSelection();
            }}>
              {/* SELECT  */}
              {props.connectionAppliesToFilter && (
                <div className="custom-control custom-radio">
                  <input
                    onChange={() => { }}
                    className="form-check-input rbConnectionSelect"
                    type="radio"
                    checked={props.ConnectionIndex === props.SelectedConnection}
                  />
                  <label className="form-check-label">&zwnj;</label>
                </div>
              )}
            </td>
          )}
          <td className="text-truncate fareTabletrtdRouting" onClick={(e) => {
            e.stopPropagation();
            OnConnectionSelection();
          }}>
            {" "}
            {/* ROUTING  */}
            {props.Data.segments!.map((segment, index) => (
              <span key={"ROUTING" + index}>
                <span>{segment.departureAirport!.iata}</span>-
                <span>{segment.arrivalAirport!.iata}</span>
                <br />
              </span>
            ))}
          </td>
          {(!props.cabinIsPresentAll || props.isCabinVisibleInTable) && (
            <td className="text-truncate fareTabletrtdCabin" onClick={(e) => {
              e.stopPropagation();
              OnConnectionSelection();
            }}>
              {" "}
              {/* CabinClass  */}
              {props.Data.segments!.map((segment, index) => (
                <span key={"CabinClass" + index}>
                  <span
                    style={{
                      fontWeight: `${segment.cabinClassName == "Economy" ? 400 : 600
                        }`,
                    }}
                  >
                    {segment.cabinClassName == "PremiumEconomy"
                      ? "Premium"
                      : segment.cabinClassName}
                  </span>
                  <br />
                </span>
              ))}
            </td>
          )}
          <td
            className="text-truncate fareTabletrtdFlight"
            onClick={(e) => {
              e.stopPropagation();
              OnConnectionSelection();
            }}
          >
            {" "}
            {/* FLIGHT  */}
            {props.Data.segments!.map((segment, index) => (
              <span key={"FLIGHT" + index}>
                <span>
                  {segment.carrier.code}
                  {/* {segment.flightNumber} */}
                  {padNumberWithZeros(segment.flightNumber)}
                  &nbsp;by&nbsp;
                  {segment?.codeShare?.code && segment?.codeShare?.hint?.length
                    ? segment?.codeShare?.hint
                    : segment?.carrier?.hint}
                </span>
                <br />
              </span>
            ))}
          </td>
          <td
            className="text-truncate fareTabletrtdDate"
            onClick={(e) => {
              e.stopPropagation();
              OnConnectionSelection();
            }}
          >
            {" "}
            {/* DATES  */}
            {props.Data.segments!.map((segment, index) => {
              // Parse the date using Moment.js
              const dateTimeObject = moment(segment.departureDate);
              // Check the day of the weeks
              const dayOfWeek = dateTimeObject.format("dddd");
              return (
                <>
                  <span data-toggle="tooltip" title={dayOfWeek}>
                    <span>
                      <DateDisplay date={segment.departureDate} />
                      {/* <DateFormat dates={segment.departureDate} /> */}
                    </span>
                    <br />
                  </span>
                </>
              );
            })}
          </td>
          <td
            className="text-truncate fareTabletrtdTime"
            onClick={(e) => {
              e.stopPropagation();
              OnConnectionSelection();
            }}
          >
            {" "}
            {/* TIMES  */}
            {props.Data.segments!.map((segment, index) => (
              <span key={"props.Data.segments4" + index}>
                <span>
                  <span>
                    <TimeDisplay date={segment.departureDate} /> -{" "}
                  </span>
                  <span>
                    <TimeDisplay date={segment.arrivalDate} />
                    <span className="nextday">
                      {" "}
                      <sup>{segment.dayIndicator}</sup>
                    </span>
                  </span>
                </span>
                <br />
              </span>
            ))}
          </td>
          <td
            className="text-truncate fareTabletrtdType"
            onClick={(e) => {
              e.stopPropagation();
              OnConnectionSelection();
            }}
          >
            {" "}
            {/* TYPE  */}
            {props.Data.segments!.map((segment, index) =>
              segment.equipmentCode == segment.equipmentName ? (
                <span key={"props.Data.segments2" + index} >
                  <span>{segment.equipmentCode}</span>
                  <br />
                </span>
              ) : (
                <span key={"props.Data.segments2" + index}>
                  <span style={{ textTransform: 'capitalize' }}> {capitalizeFirstAndUpperCaseRest(segment.equipmentName)}</span>
                  <br />
                </span>
              )
            )}
          </td>
          {!props.baggageIsPresentAll && (
            <td
              className="text-truncate fareTabletrtdBaggage"
              onClick={(e) => {
                e.stopPropagation();
                OnConnectionSelection();
              }}
            >
              {" "}
              {/* BAGGAGE */}
              {props.Data.segments!.map((segment, index) => (
                <span key={"props.Data.segments6" + index}>
                  <span>
                    {!segment.freeBaggageAllowance ? (
                      <>
                        <FontAwesomeIcon
                          style={{
                            opacity: !segment.freeBaggageAllowance ? 0.3 : 1,
                          }}
                          title="This fare has no free baggage allowance."
                          icon={faLuggageCart}
                        />
                        <FontAwesomeIcon
                          style={
                            {
                              "--fa-secondary-opacity": "0.7",
                              "--fa-primary-color": "black",
                              color: `${!segment.freeBaggageAllowance ? "red" : ""
                                }`,
                            } as CSSProperties
                          }
                          icon={
                            !segment.freeBaggageAllowance
                              ? faExclamationTriangle
                              : faCheckCircle
                          }
                          transform="shrink-7 down-4 left-9"
                        />
                      </>
                    ) : (
                      segment.freeBaggageAllowance
                    )}
                  </span>
                  <br />
                </span>
              ))}
            </td>
          )}

          {!props.resultIsPresentAllIncl && (
            <td className="fareTabletrtdServices" onClick={(e) => {
              e.stopPropagation();
              OnConnectionSelection();
            }}>
              {" "}
              {/* INCLUDEDSERVICES  */}
              <span className="ancillaries text-s">
                {props.Data.segments!.map((segment, index) => (
                  <span key={"props.Data.segments7" + segment.index + index}>
                    <span>
                      {segment.includedServices && (
                        <React.Fragment>
                          {segment.includedServices.map(
                            (mainGroup, mainGroupIndex) => (
                              <IncServices data={mainGroup} toShow={true} iconDataDefaultHeight={iconDataDefaultHeight} fullToolTip={false} includedServices={segment.includedServices} />
                            )
                          )}
                        </React.Fragment>
                      )}
                    </span>
                    <br />
                  </span>
                ))}
              </span>
            </td>
          )}

          {
            props?.Data?.mealCode?.length > 0 && (
              <td className="fareTabletrtdMeal" onClick={(e) => {
                e.stopPropagation();
                OnConnectionSelection();
              }}>
                {props.Data.segments!.map((segment, indexM) => (
                  <div>
                    {!segment.mealCode.length || segment.mealCode == "N" ? null :
                      <p key={indexM} className="mb-0">
                        <Tooltip
                          placement="right"
                          title={
                            <>
                              <span>
                                {
                                  allIcons?.[segment.mealCode]?.hint !== undefined ? allIcons?.[segment.mealCode]?.hint : segment.mealCode
                                }
                              </span>
                            </>
                          }>
                          <img
                            style={{ marginTop: "2px" }}
                            src={`${allIcons?.[getRightIcon(segment.mealCode)]?.icon}`}
                            height={`${iconDataDefaultHeight}px`}
                            alt={`${segment.mealCode}`}
                          />
                        </Tooltip>
                      </p>
                    }
                  </div>
                ))}
              </td>
            )
          }

          {SegmentHasAllotment() && (
            <td className="text-center align-middle clickme" onClick={(e) => {
              e.stopPropagation();
              OnConnectionSelection();
            }}>
              {" "}
              {/* DETAILS  */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  OnConnectionSelection();
                }}
                className="btn btn-sm"
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </td>
          )}
        </tr>
      )}

      <AllotmentModal
        connection={props.Data}
        isopen={AllotmentModalOpen}
        toggle={ToggleAllotmentModal}
      />
    </React.Fragment>
  );
};

export default FareLegConnectionTableRow;