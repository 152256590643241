import { AvailableFares } from "components/AvailableFares/component/AvailableFares";
import { ServiceAddObj } from "components/Fare/AncillariesModal";
import { FareBookingShell } from "components/FareBooking/component/FareBooking";
import { useSessionHandler } from "hooks/useSessionHandler";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Alert } from "reactstrap";
import { State } from "rootExports/rootReducer";
import {
  AvailableFareModel,
  b2cSettingsText,
  FareSearchSubmitApiResponse,
  UpdateCustomerProfileDataRequest,
} from "WebApiClient";
import LandingPage from "Pages/LandingPage";
import LanguageSelector from "components/General/LanguageSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import * as Moment from "moment";
import { useUserInformation } from "hooks/various";

export interface SetBookingPayload {
  Fare: AvailableFareModel;
  SelectedConnections: number[];
  AddedServices: ServiceAddObj[];
  OnSelectConnection: (legIndex: number, connectionIndex: number) => void;
}

const AvailableFaresPage: React.FC<{ isB2cAirline: boolean }> = (props) => {
  const [fetching, setFetching] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [response, setResponse] = useState<any>(undefined);

    const [fareSortStores, setFareSortStores] = useState({BestFare:[],BestCarousel:[],cheapestCarousel:[],cheapestFare:[],fastFare:[],fastCarousel:[]})

  //refreshToken timer
  // const sessionHandler = useSessionHandler();
  // const userInfo = useUserInformation();
  // const expires = Moment.unix(userInfo.ValidUntil);

  // useEffect(() => {
  //   const targetTime = expires.toDate().getTime();
  //   const tenMinutesInMs = 1 * 60 * 60 * 1000;;
  //   const checkTime = () => {
  //     const currentTime = new Date().getTime();
  //     const timeDifference = targetTime - currentTime;
  //     if (timeDifference <= tenMinutesInMs && timeDifference > 0) {
  //       sessionHandler.RefreshSession();
  //     }
  //   };
  //   // Check every 5 minute
  //   const intervalId = setInterval(checkTime, 300000);
  //   // Clean up the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [expires.toDate()]);

  // useEffect(() => {
  //   const targetTime = expires.toDate().getTime();
  //   const tenMinutesInMs = 10 * 60 * 1000; // 10 minutes in milliseconds
  //   const checkTime = () => {
  //     const currentTime = new Date().getTime();
  //     const timeDifference = targetTime - currentTime;
  //     // Check if the time difference is exactly 10 minutes or less but greater than 0
  //     if (timeDifference <= tenMinutesInMs && timeDifference > 0) {
  //       console.log("Session about to expire, refreshing session...");
  //       sessionHandler.RefreshSession();
  //       clearInterval(intervalId); // Clear the interval after refreshing the session
  //     }
  //   };
  //   // Check every 1 minute
  //   const intervalId = setInterval(checkTime, 60000);
  //   // Initial check to handle cases where the interval might be too long
  //   checkTime();
  //   // Clean up the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [expires]);

  const DEBUG = true;
  const sessionHook = useSessionHandler();
  const [heightHolder, setHeightHolder] = useState<any>(0);
  const [submitResult, setSubmitResult] = useState<FareSearchSubmitApiResponse | undefined>(undefined);
  const fareResult = submitResult?.result?.fareSearchResult;
  var originalName: any;
  if (submitResult && submitResult.result && submitResult.result.fareSearchResult) {
    originalName = _.cloneDeep(submitResult.result.fareSearchResult);
    // Now you can use originalName as needed
  } else {
    // If any of the properties is undefined, use fareResult as a fallback
    originalName = _.cloneDeep(fareResult);
    // Now you can use originalName as needed
  }
  const [activeTab, setActiveTab] = useState("1");
  const [activeTabHF, setActiveTabHF] = useState("1");
  const [currentBooking, setCurrentBooking] = useState<SetBookingPayload | undefined>(undefined);
  const customerProfileData: UpdateCustomerProfileDataRequest | undefined = useSelector((state: State) => state.Session.FrontendSettings.customerProfileData);
  function OnSearchSubmit(response: FareSearchSubmitApiResponse) {
    setSubmitResult(undefined);
    setSubmitResult(response);
    setCurrentBooking(undefined);
    if (response.result?.fareSearchResult) {
      setActiveTab("2");
    }
    sessionHook.RefreshSession();
  }

  useEffect(() => {
    // dispatch(Session_Saga_RefreshToken());
    document.title = `Flights`;
    if (DEBUG) {
      console.log(`Available Fares Page initialized.`);
    }
    sessionHook.RefreshSession();
  }, []);

  function BookFare(payload: SetBookingPayload) {
    window.scrollTo(0, 0);
    setCurrentBooking(payload);
    setActiveTab("3");
  }

  function OnModify() {
    window.scrollTo(0, 0);
    setActiveTab("1");
  }

  function OnDiscard() {
    window.scrollTo(0, 0);
    setActiveTab("1");
    setSubmitResult(undefined);
    setCurrentBooking(undefined);
    // history.replace(`/availfares`)rrrrrrrrrrrr
  }

  //state for making visibility for ancillary services
  const [extraAncCheck, setExtraAncCheck] = useState<boolean>(true);
  const [language, setLanguage] = useState("en");

  const sessionInfo = useSelector((state: State) => state.Session);

  const Filter: b2cSettingsText = (() => {
    try {
      return JSON.parse(
        sessionInfo?.FrontendSettings?.b2cSettingsText ||
        '{"HotelTab":"None","AirPortSearchType":"All","ShowLanguageDecimalTabB2C":"English","ShowExtraAncillariesTabB2C":"On","ShowDateInput":"Left","ShowDefaultFilter":"Off","showFilterPosition": "Right"}'
      );
    } catch {
      return {
        HotelTab: "All",
        AirPortSearchType: "All",
        ShowLanguageDecimalTabB2C: "English",
        ShowExtraAncillariesTabB2C: "On",
        ShowDateInput: "Left",
        ShowDefaultFilter: "Off",
        showFilterPosition: "Right"
      };
    }
  })();

  return (
    <div>
      <div>
        {customerProfileData && (
          <Alert color="warning">
            Customer profile data{" "}
            {customerProfileData.title &&
              customerProfileData.title.length > 0 && (
                <span className="font-weight-bold">
                  "{customerProfileData.title}"
                </span>
              )}{" "}
            is active. Profile will remain active until logout.
          </Alert>
        )}
        <div className="">
          <div className={`${activeTab !== "1" ? "d-none" : ""}`}>
            <LandingPage
              OnReset={OnDiscard}
              IsModifySearch={false}
              OnSearchSubmitted={OnSearchSubmit}
              LastSubmitted={fareResult?.submittedSearch}
              submitResult={submitResult}
              GoToResults={
                fareResult
                  ? () => {
                    setActiveTab("2");
                  }
                  : undefined
              }
              setHeightHolder={setHeightHolder}
              activeTabHF={activeTabHF}
              setActiveTabHF={setActiveTabHF}
              language={language}
              isB2cAirline={props.isB2cAirline}
            />
          </div>
          <div className={`${activeTab !== "2" ? "d-none" : ""}`}>
            {fareResult && (
              <AvailableFares
                OriginalFare={originalName}
                data={fareResult}
                BookFare={BookFare}
                OnDiscard={OnDiscard}
                OnModify={OnModify}
                IsActive={activeTab === "2"}
                key={fareResult.fareResultID}
                OnReset={OnDiscard}
                IsModifySearch={false}
                OnSearchSubmitted={OnSearchSubmit}
                LastSubmitted={fareResult?.submittedSearch}
                submitResult={submitResult}
                GoToResults={
                  fareResult
                    ? () => {
                      setActiveTab("2");
                    }
                    : undefined
                }
                setHeightHolder={setHeightHolder}
                heightHolder={heightHolder}
                activeTabHF={activeTabHF}
                setActiveTabHF={setActiveTabHF}
                setFetching={setFetching}
                setError={setError}
                setResponse={setResponse}
                setExtraAncCheck={setExtraAncCheck}
                extraAncCheck={extraAncCheck}
                language={language}
                setFareSortStores={setFareSortStores}
                fareSortStores={fareSortStores}
              />
            )}
          </div>
          <div className={`${activeTab !== "3" ? "d-none" : ""}`}>
            <FareBookingShell
              Intit={currentBooking}
              multiChannel={fareResult?.multiChannelRuleInfo}
              Discard={() => {
                setCurrentBooking(undefined);
              }}
              OnNewQuery={OnDiscard}
              GoToFares={() => {
                setActiveTab("2");
              }}
              OnModify={OnModify}
              OnReset={OnDiscard}
              IsModifySearch={false}
              OnSearchSubmitted={OnSearchSubmit}
              LastSubmitted={fareResult?.submittedSearch}
              submitResult={submitResult}
              GoToResults={
                fareResult
                  ? () => {
                    setActiveTab("2");
                  }
                  : undefined
              }
              Config={undefined}
              Init={undefined}
              fetching={fetching}
              error={error}
              response={response}
              setExtraAncCheck={setExtraAncCheck}
              extraAncCheck={extraAncCheck}
            />
          </div>
        </div>
        <div>
          <div className="languageContainer">
            <LanguageSelector setLanguage={setLanguage} />
          </div>
        </div>
      </div>
      {activeTab == "2" && (
        <div
          className={Filter?.showFilterPosition == "Right" ? "farePrevBtn" : "farePrevBtn leftPositionBtn"}
          style={{ position: "fixed", top: "20vh", left: "0px" }}
        >
          <div className="btn-group btn-group-vertical">
            <button
              style={{ borderRadius: "0px 8px 8px 0px" }}
              className="btn btn-primary btn-lg mb-2 py-4 PrevBtn"
              onClick={() => {
                setActiveTab("1");
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvailableFaresPage;