import { AvailableFares } from "components/AvailableFares/component/AvailableFares";
import { ServiceAddObj } from "components/Fare/AncillariesModal";
import { FareBookingShell } from "components/FareBooking/component/FareBooking";
import { useSessionHandler } from "hooks/useSessionHandler";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Alert } from "reactstrap";
import { State } from "rootExports/rootReducer";
import {
  AvailableFareModel,
  FareSearchSubmitApiResponse,
  UpdateCustomerProfileDataRequest,
} from "WebApiClient";

import LanguageSelector from "components/General/LanguageSelector";
import LandingPage2 from "./LandingPage2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";

export interface SetBookingPayload {
  Fare: AvailableFareModel;
  SelectedConnections: number[];
  AddedServices: ServiceAddObj[];
  OnSelectConnection: (legIndex: number, connectionIndex: number) => void;

}


const AvailableFaresPageCopy: React.FC<{ isB2cAirline: boolean; }> = (props) => {
  const [fetching, setFetching] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [response, setResponse] = useState<any>(undefined);
  const DEBUG = true;
  const sessionHook = useSessionHandler();
  const [heightHolder, setHeightHolder] = useState<any>(0);
  const [submitResult, setSubmitResult] = useState<FareSearchSubmitApiResponse | undefined>(undefined);
  const fareResult = submitResult?.result?.fareSearchResult;
  var originalName: any;

  if (
    submitResult &&
    submitResult.result &&
    submitResult.result.fareSearchResult
  ) {
    originalName = _.cloneDeep(submitResult.result.fareSearchResult);
    // Now you can use originalName as needed
  } else {
    originalName = _.cloneDeep(fareResult);
  };

  const [language, setLanguage] = useState("en");
  const [activeTab, setActiveTab] = useState("1");
  const [activeTabHF, setActiveTabHF] = useState("1");
  const [currentBooking, setCurrentBooking] = useState<SetBookingPayload | undefined>(undefined);
  const customerProfileData: UpdateCustomerProfileDataRequest | undefined = useSelector((state: State) => state.Session.FrontendSettings.customerProfileData);
  const [fareSortStores, setFareSortStores] = useState({BestFare:[],BestCarousel:[],cheapestCarousel:[],cheapestFare:[],fastFare:[],fastCarousel:[]})

  const sendPageHeight1 = () => {
    const height = document.body.scrollHeight;
    window.parent.postMessage(["setHeight", height + "px"], "*");
  };

  const sendPageHeight = () => {
    const height =
      activeTab == "1"
        ? 600
        : activeTab == "2"
          ? document.body.scrollHeight
          : 500;
    window.parent.postMessage(["setHeight", height + "px"], "*");
    if (activeTab == "3") {
      setTimeout(() => {
        sendPageHeight1();
      }, 1000);
    } else if (activeTab == "2") {
      setTimeout(() => {
        sendPageHeight1();
      }, 500);
    } else if (activeTab == "1") {
      setTimeout(() => {
        sendPageHeight1();
      }, 500);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      sendPageHeight();
    }, 500);
  }, [activeTab]);

  function OnSearchSubmit(response: FareSearchSubmitApiResponse) {
    setSubmitResult(undefined);
    setSubmitResult(response);
    setCurrentBooking(undefined);
    if (response.result?.fareSearchResult) {
      setActiveTab("2");
    }
    sessionHook.RefreshSession();
  }

  useEffect(() => {
    // dispatch(Session_Saga_RefreshToken());
    document.title = `Flights`;
    if (DEBUG) {
      console.log(`Available Fares Page initialized.`);
    }
    sessionHook.RefreshSession();
    sendPageHeight();
  }, []);

  function BookFare(payload: SetBookingPayload) {
    window.scrollTo(0, 0);
    setCurrentBooking(payload);
    setActiveTab("3");
  };

  function OnModify() {
    window.scrollTo(0, 0);
    setActiveTab("1");
  };

  function OnDiscard() {
    window.scrollTo(0, 0);
    setActiveTab("1");
    setSubmitResult(undefined);
    setCurrentBooking(undefined);
  };

  return (
    <div>
      <div>
        {customerProfileData && (
          <Alert color="warning">
            Customer profile data{" "}
            {customerProfileData.title &&
              customerProfileData.title.length > 0 && (
                <span className="font-weight-bold">
                  "{customerProfileData.title}"
                </span>
              )}{" "}
            is active. Profile will remain active until logout.
          </Alert>
        )}
        <div className="avialFarePageContainer">
          <div className={`${activeTab !== "1" ? "d-none" : ""}`}>
            <LandingPage2
              OnReset={OnDiscard}
              IsModifySearch={false}
              OnSearchSubmitted={OnSearchSubmit}
              LastSubmitted={fareResult?.submittedSearch}
              submitResult={submitResult}
              GoToResults={
                fareResult
                  ? () => {
                    setActiveTab("2");
                  }
                  : undefined
              }
              setHeightHolder={setHeightHolder}
              activeTabHF={activeTabHF}
              setActiveTabHF={setActiveTabHF}
              language={language}
              isB2cAirline={props.isB2cAirline}
            />
          </div>
          <div className={`${activeTab !== "2" ? "d-none" : ""}`}>
            {fareResult && (
              <AvailableFares
                OriginalFare={originalName}
                data={fareResult}
                BookFare={BookFare}
                OnDiscard={OnDiscard}
                OnModify={OnModify}
                IsActive={activeTab === "2"}
                key={fareResult.fareResultID}
                OnReset={OnDiscard}
                IsModifySearch={false}
                OnSearchSubmitted={OnSearchSubmit}
                LastSubmitted={fareResult?.submittedSearch}
                submitResult={submitResult}
                GoToResults={
                  fareResult
                    ? () => {
                      setActiveTab("2");
                    }
                    : undefined
                }
                setHeightHolder={setHeightHolder}
                heightHolder={heightHolder}
                activeTabHF={activeTabHF}
                setActiveTabHF={setActiveTabHF}
                setFetching={setFetching}
                setError={setError}
                setResponse={setResponse}
                language={language}
                setFareSortStores={setFareSortStores}
                fareSortStores={fareSortStores}
              />
            )}
          </div>
          <div className={`${activeTab !== "3" ? "d-none" : ""}`}>
            <FareBookingShell
              Intit={currentBooking}
              multiChannel={fareResult?.multiChannelRuleInfo}
              Discard={() => {
                setCurrentBooking(undefined);
              }}
              OnNewQuery={OnDiscard}
              GoToFares={() => {
                setActiveTab("2");
              }}
              OnModify={OnModify}
              OnReset={OnDiscard}
              IsModifySearch={false}
              OnSearchSubmitted={OnSearchSubmit}
              LastSubmitted={fareResult?.submittedSearch}
              submitResult={submitResult}
              GoToResults={
                fareResult
                  ? () => {
                    setActiveTab("2");
                  }
                  : undefined
              }
              Config={undefined}
              Init={undefined}
              fetching={fetching}
              error={error}
              response={response}
            />
          </div>
        </div>
        <div>
          <div className="languageContainer">
            {/* <span>{t("LandingPage.menu.help")}</span> &nbsp;&nbsp; */}
            <LanguageSelector setLanguage={setLanguage} />
          </div>
        </div>
      </div>

      {activeTab == "2" && (
        <div
          className="farePrevBtn"
          style={{ position: "fixed", top: "20vh", left: "0px" }}
        >
          <div className="btn-group btn-group-vertical">
            {/* <button
                        className="btn btn-primary btn-lg mb-2"
                        onClick={IncrementTab}
                        disabled={nextTabDisabled}
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button> */}
            <button
              style={{ borderRadius: "0px 8px 8px 0px" }}
              className="btn btn-primary btn-lg mb-2 py-4 PrevBtn"
              onClick={() => {
                setActiveTab("1");
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
          </div>
        </div>

      )}
    </div>
  );
};

export default AvailableFaresPageCopy;
