 // Ensure you have the correct path to your root reducer
import image25Kg from '../../../Styles/images/IncludedServIcons/25K.png';
import image02P from '../../../Styles/images/IncludedServIcons/02P.png';
import { IconData } from 'WebApiClient';

interface IconEntry {
    icon: string; // Could be a string (for emojis) or an import (for image paths)
    hint: string; // Description or hint associated with the icon
}

// Define the main interface for the SabreIconsEmoji object

interface SabreIconsEmoji {
    [key: string]: IconEntry; // Keys can be any string representing the icon code
}

// export const SabreIconsEmoji = (): Record<string, IconEntry> => {
//     // Use selector to get the icon data from the Redux store
//     // const iconData: IconData[] = useSelector((state: State) => state.Session.FrontendSettings.iconDataSettings);    
//     const iconData: IconData[] = useSelector((state: State) => state.Session.FrontendSettings.iconDataSettings);

//     // Transform the icon data into a usable format
//     const icons = iconData.reduce((acc, { code, hint, defaultIcon, newIcon }) => {
//         acc[code] = { icon: newIcon?.length || newIcon != null ? newIcon : defaultIcon, hint }; // Construct the icon entry
//         return acc; // Return the accumulator for the next iteration
//     }, {} as Record<string, IconEntry>); // Initialize the accumulator

//     // Add the static values
//     const staticIcons = {
//         "01P": { icon: BAGGAGENEW, hint: "" },
//         "02P": { icon: image02P, hint: "" },
//         "25K": { icon: image25Kg, hint: "" }
//     };

//     // Merge dynamic icons with static ones
//     const allIcons = { ...icons, ...staticIcons };
//     return allIcons;
// };

export const SabreIconsEmoji = (iconData: IconData[] | null | undefined): Record<string, IconEntry> => {
    if (!iconData) return {}; // Handle null or undefined

    // Transform the icon data into a usable format
    const icons = iconData.reduce((acc, { code, hint, defaultIcon, newIcon }) => {
        acc[code] = { icon: newIcon?.length || newIcon != null ? newIcon : defaultIcon, hint };
        return acc;
    }, {} as Record<string, IconEntry>);

    // Add static values
    const staticIcons = {
        // "Checked baggage": { icon: "BAGGAGENEW", hint: "" }, // Adjusted key for consistency
        "02P": { icon: image02P, hint: "" },
        "25K": { icon: image25Kg, hint: "" }
    };

    return { ...icons, ...staticIcons }; // Merge dynamic and static icons
};