import {
    FaresFilterState,
    FreeBaggageFilterCriteria,
    IncludedServiceSubFilterCriteria,
} from "components/AvailableFares/types/AvailableFaresTypes";
import _ from "lodash";
import * as moment from "moment";
import { useState } from "react";
import {
    AvailableFareConnectionModel,
    AvailableFareModel,
    IncludedServiceData,
} from "WebApiClient";
export interface FareFilterApplicability {
    Applicable: boolean;
    ApplicableConnections?: Array<number[]>;
}

var totalTravelStop = 0;
export function FareAppliesToFilter(
    fare: AvailableFareModel,
    filter: FaresFilterState
): FareFilterApplicability {
    let applies = true;
    let applicableConnections: Array<number[]> = [];


    function standardizeBaggageType(value: string): string {
        const match = value.match(/^(\d*)([a-zA-Z]*)$/);

        if (!match) return value; // If no match, return the value as is

        let [_, numberPart, typePart] = match;

        // Remove leading zeros in the number part (e.g., "01" -> "1")
        numberPart = numberPart ? parseInt(numberPart, 10).toString() : "";

        // Convert the baggage type part to uppercase
        typePart = typePart.toUpperCase();

        // Normalize similar baggage types ("P" and "PC" -> "PC")
        if (typePart === "P" || typePart === "PC") {
            typePart = "PC";
        } else if (typePart === "K") {
            typePart = "K";
        }

        // Return the standardized value (e.g., "1PC" or "20K")
        return value=="0K" ? "" : numberPart + typePart;
    }

    if (!filter.BaggageType.some((e) => e.Checked && standardizeBaggageType(e.Value) === standardizeBaggageType(fare.freeBaggageAllowance))) {
        applies = false; // If no matching checked baggage type is found, set applies to false
    }

    if (fare.fareInfoBox) {
        if (filter.FareTypes.length > 0) {
            fare.legs.forEach((leg, legIndex) => {
                let faretype = "";
                if (leg.fareTypeInformation.salesPreferenceDisplayName) {
                    faretype =
                        leg.fareTypeInformation.salesPreferenceDisplayName;
                } else {
                    faretype = leg.fareTypeInformation.originalFareType;
                }

                if (
                    !filter.FareTypes.some(
                        (e) => e.Checked && e.Value === faretype
                    )
                ) {
                    applies = false;
                }
            });
        }
        if (filter.QueryPCCs.length > 0) {
            if (fare.fareInfoBox.queryPccs) {
                const filterQueryPCCs = filter.QueryPCCs.filter(
                    (e) => e.Checked === true
                ).map((e) => e.Value);
                if (
                    !fare.fareInfoBox.queryPccs.some((item) =>
                        filterQueryPCCs.includes(item)
                    )
                ) {
                    // console.log("filter fail: querypcc")
                    applies = false;
                }
            }
        }
        if (filter.BookingPCCs.length > 0) {
            if (fare.fareInfoBox.bookingPccs) {
                const filterBookingPCCs = filter.BookingPCCs.filter(
                    (e) => e.Checked === true
                ).map((e) => e.Value);
                if (
                    !fare.fareInfoBox.bookingPccs.some((item) =>
                        filterBookingPCCs.includes(item)
                    )
                ) {
                    // console.log("filter fail: booking  pocc")
                    applies = false;
                }
            }
        }
        if (filter.Sources.length > 0) {
            if (fare.fareInfoBox.sources) {
                const filterSources = filter.Sources.filter(
                    (e) => e.Checked === true
                ).map((e) => e.Value);
                if (
                    !fare.fareInfoBox.sources.some((item) =>
                        filterSources.includes(item)
                    )
                ) {
                    // console.log("filter fail: source")
                    applies = false;
                }
            }
        }
    }
    const legCount = fare.legs.length;
    if (applies) {
        fare.legs.forEach((leg, legIndex) => {
            let legApplicableConnections: number[] = [];

            if (leg.platingCarrier) {
                if (leg.platingCarrier.code && leg.platingCarrier.code !== "") {
                    if (filter.Airlines.length > 0) {
                        if (
                            !filter.Airlines.some(
                                (e) =>
                                    e.Checked &&
                                    e.Value === leg.platingCarrier!.code!
                            )
                        ) {
                            applies = false;
                            // console.log("filter fail: airline")
                            return;
                        }
                    }
                }
                if (
                    leg.platingCarrier.allianceCode &&
                    leg.platingCarrier!.allianceCode !== ""
                ) {
                    if (filter.Alliances.length > 0) {
                        if (
                            !filter.Alliances.some(
                                (e) =>
                                    e.Checked &&
                                    e.Value ===
                                    leg.platingCarrier!.allianceCode!
                            )
                        ) {
                            applies = false;
                            return;
                        }
                    }
                }
            }

            const totalTravelStops = _.sumBy(fare.legs, 'connections[0].connectionHeader.travelStopps');
            leg.connections!.forEach((connection, connectionIndex) => {
                const connectionApplies = ConnectionAppliesToFilter(
                    connection,
                    filter,
                    legIndex,
                    totalTravelStops
                );

                if (connectionApplies) {
                    legApplicableConnections.push(connectionIndex);
                }
            });
            totalTravelStop = 0;
            if (legApplicableConnections.length === 0) {
                applies = false;
                return;
            }
            applicableConnections.push(legApplicableConnections);
        });
    }
    if (applicableConnections.length !== legCount) {
        applies = false;
    }
    let result: FareFilterApplicability = {
        Applicable: false,
        ApplicableConnections: undefined,
    };
    if (applies) {
        result = {
            Applicable: true,
            ApplicableConnections: applicableConnections,
        };
    } else {
        result = { Applicable: false };
    }

    return result;
}

function ConnectionAppliesToFilter(
    connection: AvailableFareConnectionModel,
    filter: FaresFilterState,
    legIndex: number,
    totalTravelStops: number,
): boolean {
    let result = true;
    // connection filter start
    const StopFilter: number[] = Object.keys(filter.LegsMaxStopAmount.MaxStopSelection)
        .filter((key: any) => filter.LegsMaxStopAmount.MaxStopSelection[key])
        .map(Number);
    if (StopFilter.length) {
        if (connection.connectionHeader?.travelStopps !== undefined) {
            const additionalStops = connection.connectionHeader.travelStopps;
            if (!isNaN(additionalStops)) {
                totalTravelStop += additionalStops;
            }
        }
        result = StopFilter.includes(totalTravelStops);
    }

    // connection filter end
    // Segment filter start
    connection.segments!.forEach((segment, segmentIndex) => {
        const isfirstSegment = segmentIndex === 0;
        const isLastSegment = segmentIndex === connection.segments!.length - 1;

        if (
            !filter.CabinClasses.some(
                (e) => e.Checked && e.Value === segment.cabinClassName
            )
        ) {
            result = false;
            return;
        }

        const segmentAllotment = segment.allotmentInformation;
        if (segmentAllotment) {
            filter.AllotmentTypes.forEach((elem) => {
                if (elem.Checked) {
                    if (segmentAllotment.types.some((e) => e === elem.Value)) {
                        return;
                    }
                } else {
                    result = false;
                    return;
                }
            });
        }

        const filterMainGroups: IncludedServiceSubFilterCriteria[] = [];
        switch (filter.FreeBaggage) {
            case FreeBaggageFilterCriteria.No:
                if (segment.hasFreeBaggage) {
                    // console.log("filter fail: baggage");
                    result = false;
                    return;
                }
                break;
            case FreeBaggageFilterCriteria.Yes:
                if (!segment.hasFreeBaggage) {
                    // console.log("filter fail: baggage");
                    result = false;
                    return;
                }
                break;
        }

        filter.IncludedService.forEach((elem) => {
            elem.Values.forEach((sub) => {
                filterMainGroups.push(sub);
            });
        });

        let segmentMainGroups: IncludedServiceData[] = [];
        if (segment.includedServices) {
            segment.includedServices.forEach((elem) => {
                if (elem.services) {
                    elem.services.forEach((sub) => {
                        segmentMainGroups.push(sub);
                    });
                }
            });
        }

        filterMainGroups.forEach((type) => {
            if (type.Checked) {
                if (
                    !segmentMainGroups.some(
                        (e) =>
                            e.hint!.toLowerCase() === type.Value.toLowerCase()
                    )
                ) {
                    result = false;
                    // console.log("filter fail: included service");

                    return;
                }
            }
        });
        if (isfirstSegment) {
            const filterdepartureTime = filter.DepartureTime[legIndex];
            if (
                SegmentDateNotInRange(
                    filterdepartureTime.MinHour,
                    filterdepartureTime.MaxHour,
                    segment.departureDate
                )
            ) {
                result = false;
                // console.log("filter fail: depature time");

                return;
            }
            if (
                !filter.DepartureAirports.some(
                    (e) =>
                        e.Checked && e.Value === segment.departureAirport!.iata!
                )
            ) {
                result = false;
                return;
            }
        }
        if (isLastSegment) {
            const filterArrivalTime = filter.ArrivalTime[legIndex];
            if (
                SegmentDateNotInRange(
                    filterArrivalTime.MinHour,
                    filterArrivalTime.MaxHour,
                    segment.arrivalDate
                )
            ) {
                result = false;
                // console.log("filter fail: arrival time");
                return;
            }
            if (
                !filter.ArrivalAirports.some(
                    (e) =>
                        e.Checked && e.Value === segment.arrivalAirport!.iata!
                )
            ) {
                result = false;
                return;
            }
        }
    });

    return result;
}

function SegmentDateNotInRange(
    filterMinTime: number,
    filterMaxTime: number,
    segmentTime: Date | undefined
): boolean {
    if (segmentTime) {
        const segmentHour = moment(segmentTime).hour();
        const segmentMinute = moment(segmentTime).minute();

        const filterMinHoursVal: number = Math.floor(filterMinTime);
        const filterMinMinutesVal: number =
            (((filterMinTime % 1) * 100) / 100) * 60;

        const filterMaxHoursVal = Math.floor(filterMaxTime);
        const filterMaxMinutesVal = (((filterMaxTime % 1) * 100) / 100) * 60;

        const minTime = filterMinHoursVal + filterMinMinutesVal / 100;
        const maxTime = filterMaxHoursVal + filterMaxMinutesVal / 100;
        const timeVal = segmentHour + segmentMinute / 100;
        const oor = timeVal < minTime || timeVal > maxTime;
        if (oor) {
            return true;
        }

        return false;
    } else {
        console.error("TIME IS NULL");
        return false;
    }
}
