import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  faDollar,
  faHandHoldingDollar,
  faClock,
  faGauge,
  faGaugeHigh,
  faRoute,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { currencyFormatter } from "components/Shared/Helpers/CurrencyConverter";
import _ from "lodash";
import {
  AvailableFareLegModel,
  AvailableFareModel,
  FlightData,
  TravelTimeView,
  b2cSettingsText,
} from "WebApiClient";
import Carousel from "react-multi-carousel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LocalizationConverter } from "components/Shared/Helpers/LocalizationConverter";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
interface sortFunction {
  fares: AvailableFareModel[];
  cheapestFare: () => void;
  fastestFare: any;
  bestFare: any;
  shortFare: any;
  DeactivateAllAlliances: any;
  cssActiveChecker: any;
  repeatChecker: string[];
  AddRepeteValu1: () => void;
  AddRepeteValu2: () => void;
  AddRepeteValu3: () => void;
  AddRepeteValu4: () => void;
  removeAllValu: () => void;
  setFilterName: any;
}

export const SortFunction: React.FC<sortFunction> = (props) => {
  const f1: any = useRef();
  const f2: any = useRef();
  const f3: any = useRef();
  const f4: any = useRef();
  const matches = useMediaQuery("(max-width:1024px)");
  const { t, i18n } = useTranslation();
  const b2cSettingsText = useSelector((state: State) => state.Session.FrontendSettings.b2cSettingsText);

  const B2CSettings: b2cSettingsText = (() => {
    try {
      return JSON.parse(b2cSettingsText || "{\"HotelTab\":\"None\",\"AirPortSearchType\":\"Most\",\"ShowLanguageDecimalTabB2C\":\"English\",\"ShowExtraAncillariesTabB2C\":\"On\",\"ShowDateInput\":\"Right\",\"ShowDefaultFilter\":\"On\",\"CabinClass\":{\"Economy\":{\"Checked\":true,\"preSelect\":true},\"Premium\":{\"Checked\":true,\"preSelect\":false},\"Business\":{\"Checked\":true,\"preSelect\":false},\"First\":{\"Checked\":false,\"preSelect\":false}},\"TermsConditionsLink\":\"https://dev.flight.agentplus.io\",\"ShowBackgroundModalColor\":\"Off\",\"showMarqueeText\":\"The flight will be delayed for 4 hours suraj\",\"showMarqueeFrom\":\"2024-05-31T10:55\",\"showMarqueeTo\":\"2024-05-30T10:58\",\"showBrandName\":\"On\",\"showAssistance\":\"On\",\"ConfirmationText\":\"<p>Thank you for booking with Everest Travel.\\n#Booking Reference#\\nYou will receive a confirmation email soon.</p>\",\"priceOption\":\"pricePerPerson\",\"Style\":\"body{\\ncolor:\\\"black\\\"\\n}\\n\"}");
    } catch {
      return { HotelTab: "All", AirPortSearchType: "All" };
    }
  })();

  const [flightDataArray, setFlightDataArray] = useState<FlightData[]>([]);

  function GetTotalFlightTime(legs: AvailableFareLegModel[]): TravelTimeView {
    let result: TravelTimeView = {
      minutes: 0,
      hours: 0,
      addHours: 0,
      daysStayOver: 0,
    };

    legs.forEach((leg, i) => {
      const sortConnection = leg.connections.sort((a: any, b: any) => {
        const legTravelTimeA = a.connectionHeader
          ? a.connectionHeader.legTravelTime.addHours * 60 +
          a.connectionHeader.legTravelTime.minutes
          : Infinity;
        const legTravelTimeB = b.connectionHeader
          ? b.connectionHeader.legTravelTime.addHours * 60 +
          b.connectionHeader.legTravelTime.minutes
          : Infinity;
        return legTravelTimeA - legTravelTimeB;
      });

      const legTime: TravelTimeView | undefined =
        sortConnection[0].connectionHeader?.legTravelTime;
      if (legTime) {
        result.minutes += legTime.minutes;
        result.hours += legTime.hours;
        result.daysStayOver += legTime.daysStayOver;
      }
    });

    result.addHours = result.daysStayOver * 24 + result.hours;
    if (result.minutes >= 60) {
      result.addHours += 1;
      result.minutes -= 60;
    }
    return result;
  }

  const handleClick = (FBox: string) => {
    // 👇️ toggle isActive state on click
    if (FBox == "f1") {
      if (!props.repeatChecker.includes("f1")) {
        f1.current.classList.add("activeCard");
        f2.current.classList.remove("activeCard");
        f3.current.classList.remove("activeCard");
        f4.current.classList.remove("activeCard");
        props.cheapestFare();
        props.DeactivateAllAlliances(true, true);
        props.AddRepeteValu1();
      } else {
        f1.current.classList.remove("activeCard");
        f2.current.classList.remove("activeCard");
        f3.current.classList.remove("activeCard");
        f4.current.classList.remove("activeCard");
        props.DeactivateAllAlliances(true, true);
        // props.accordingSalesPreference()
        props.removeAllValu();
      }
    } else if (FBox == "f2") {
      if (!props.repeatChecker.includes("f2")) {
        props.fastestFare();
        f1.current.classList.remove("activeCard");
        f2.current.classList.add("activeCard");
        f3.current.classList.remove("activeCard");
        f4.current.classList.remove("activeCard");
        props.DeactivateAllAlliances(true, true);
        props.AddRepeteValu2();
      } else {
        f1.current.classList.remove("activeCard");
        f2.current.classList.remove("activeCard");
        f3.current.classList.remove("activeCard");
        f4.current.classList.remove("activeCard");
        // props.accordingSalesPreference()
        props.removeAllValu();
      }
    } else if (FBox == "f3") {
      if (!props.repeatChecker.includes("f3")) {
        props.bestFare();
        f1.current.classList.remove("activeCard");
        f2.current.classList.remove("activeCard");
        f3.current.classList.add("activeCard");
        f4.current.classList.remove("activeCard");
        props.DeactivateAllAlliances(true, true);
        props.AddRepeteValu3();
      } else {
        f1.current.classList.remove("activeCard");
        f2.current.classList.remove("activeCard");
        f3.current.classList.remove("activeCard");
        f4.current.classList.remove("activeCard");
        // props.accordingSalesPreference()
        props.removeAllValu();
      }
    } else if (FBox == "f4") {
      if (!props.repeatChecker.includes("f4")) {
        props.shortFare();
        f1.current.classList.remove("activeCard");
        f2.current.classList.remove("activeCard");
        f3.current.classList.remove("activeCard");
        f4.current.classList.add("activeCard");
        props.DeactivateAllAlliances(true, true);
        props.AddRepeteValu4();
      } else {
        f1.current.classList.remove("activeCard");
        f2.current.classList.remove("activeCard");
        f3.current.classList.remove("activeCard");
        f4.current.classList.remove("activeCard");
        // props.accordingSalesPreference()
        props.removeAllValu();
      }
    }
  };

  useEffect(() => {
    const updatedFlightDataMap: { [fareIndex: number]: FlightData } = {};
    props.fares.forEach((fare) => {
      const totalTime: TravelTimeView = GetTotalFlightTime(fare.legs);
      fare.legs.forEach((leg) => {
        const flightData: FlightData = {
          platingCarrierCode: leg.platingCarrier?.code,
          carrierCode: leg.carrierCode,
          currency: fare.currency,
          fareIndex: leg.fareIndex,
          hint: leg.platingCarrier?.hint,
          mileage: leg.mileage,
          totalPrice: fare.totalPrice,
          salesPreferencePriority: fare.fareInfoBox.salesPreferencePriority,
          time: totalTime,
        };
        // Store flight data for each fare using the fareIndex as the key
        updatedFlightDataMap[leg.fareIndex] = flightData;
      });
    });
    // Convert flight data map to an array before updating the state
    const updatedFlightDataArray = Object.values(updatedFlightDataMap);
    setFlightDataArray(updatedFlightDataArray);
  }, [props.fares]);

  const cheapest = flightDataArray.slice().sort((a, b) => {
    // Compare totalPrice
    if (a.totalPrice !== b.totalPrice) {
      return a.totalPrice - b.totalPrice;
    }

    // If totalPrice is the same, compare time
    const timeA = a.time.addHours * 60 + a.time.minutes;
    const timeB = b.time.addHours * 60 + b.time.minutes;
    return timeA - timeB;
  });

  const fastest = flightDataArray.slice().sort((a, b) => {
    // Compare time
    if (a.time.addHours !== b.time.addHours) {
      return a.time.addHours - b.time.addHours;
    } else if (a.time.minutes !== b.time.minutes) {
      return a.time.minutes - b.time.minutes;
    }

    // If time is the same, compare totalPrice
    return a.totalPrice - b.totalPrice;
  });

  const shortFlights = _.sortBy(flightDataArray, (flight) => {
    const totalMinutes = flight.time.addHours * 60 + flight.time.minutes;
    return ((totalMinutes) / flightDataArray.length) * 100;
  });
  const short = _.uniqBy(shortFlights, "carrierCode");

  // Determine the best option based on price and time
  function calculateCombinedScore(fare: any) {
    const totalHr = fare.time.addHours + fare.time.minutes / 60;
    const normalizedPrice = fare.totalPrice / 1000; // Normalize price (adjust as needed)
    const normalizedTravelTime = totalHr / 10; // Normalize travel time (adjust as needed)
    const weightPrice = 0.6; // Weight for price
    const weightTravelTime = 0.4; // Weight for travel time
    return (weightPrice * normalizedPrice) + (weightTravelTime * normalizedTravelTime);
  }

  function sortFaresByCombinedScore(fares: any) {
    const faresWithScores = _.map(fares, fare => {
      return {
        ...fare,
        combinedScore: calculateCombinedScore(fare)
      };
    });

    const sortedFares = _.sortBy(faresWithScores, 'combinedScore');
    return sortedFares;
  }
  const best = sortFaresByCombinedScore(flightDataArray);
  const TotalPassengers = props.fares![0]?.bookingPassengers?.length;
  //carousel Sort flight
  const responsiveSort = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 701 },
      items: 3,
    },
    tabletLow: {
      breakpoint: { max: 701, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {matches ? (
        <>
          {cheapest.length || fastest.length || best.length ? (
            <div className="mainBox">

              <Carousel responsive={responsiveSort}>
                <div
                  ref={f1}
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="sortBox"
                  onClick={() => {
                    handleClick("f1");
                  }}
                >
                  <div>
                    <h3 className="mb-0"> {t("FareList.SortCard.Cheapest")}</h3>
                    <h5 className="mb-0">
                      {currencyFormatter(cheapest[0]?.currency)}{" "}

                      {
                        LocalizationConverter(
                          B2CSettings.priceOption === "pricePerPerson"
                            ? (cheapest[0]?.totalPrice / TotalPassengers)?.toFixed(2)
                            : cheapest[0]?.totalPrice?.toFixed(2),
                          i18n.language
                        )

                      }
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {cheapest[0]?.time.addHours}h {cheapest[0]?.time.minutes}m
                    </p>
                  </div>
                  <div className="headerIcon">
                    <FontAwesomeIcon
                      style={{ height: "100px", opacity: "0.2" }}
                      icon={faDollar}
                    />
                  </div>
                </div>
                <div
                  ref={f3}
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="sortBox"
                  onClick={() => {
                    handleClick("f3");
                  }}
                >
                  <div>
                    <h3 className="mb-0"> {t("FareList.SortCard.Best")}</h3>
                    <h5 className="mb-0">
                      {
                        LocalizationConverter(
                          B2CSettings.priceOption === "pricePerPerson"
                            ? (best[0]?.totalPrice / TotalPassengers)?.toFixed(2)
                            : best[0]?.totalPrice?.toFixed(2),
                          i18n.language
                        )
                      }
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {best[0]?.time.addHours}h {best[0]?.time.minutes}m{" "}
                    </p>
                  </div>
                  <div className="headerIcon">
                    <FontAwesomeIcon
                      style={{ height: "100px", opacity: "0.2" }}
                      icon={faHandHoldingDollar}
                    />
                  </div>
                </div>
                <div
                  ref={f2}
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="sortBox"
                  onClick={() => {
                    handleClick("f2");
                  }}
                >
                  <div>
                    <h3 className="mb-0">{t("FareList.SortCard.Fastest")} </h3>
                    <h5 className="mb-0">
                      {
                        LocalizationConverter(
                          B2CSettings.priceOption === "pricePerPerson"
                            ? (fastest[0]?.totalPrice / TotalPassengers)?.toFixed(2)
                            : fastest[0]?.totalPrice?.toFixed(2),
                          i18n.language
                        )
                      }
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {fastest[0]?.time.addHours}h {fastest[0]?.time.minutes}m{" "}
                    </p>
                    {/* <p> {Fastest[0]?.time[0].d}d {Fastest[0]?.time[0].h}h {Fastest[0]?.time[0].m}m ({Fastest[0]?.travelStopps[0].travelStop} Stops overall)</p> */}
                  </div>
                  <div className="headerIcon">
                    <FontAwesomeIcon
                      style={{ height: "100px", opacity: "0.2" }}
                      icon={faClock}
                    />
                  </div>
                </div>
                <div
                  ref={f4}
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="sortBox"
                  onClick={() => {
                    handleClick("f4");
                  }}
                >
                  <div>
                    <h3 className="mb-0">{t("FareList.SortCard.Shortest")} </h3>
                    <h5 className="mb-0">
                      {
                        LocalizationConverter(
                          B2CSettings.priceOption === "pricePerPerson"
                            ? (short[0]?.totalPrice / TotalPassengers)?.toFixed(2)
                            : short[0]?.totalPrice?.toFixed(2),
                          i18n.language
                        )
                      }
                    </h5>
                    <p className="mb-0">
                      {" "}
                      {short[0]?.time.addHours}h {short[0]?.time.minutes}m{" "}
                    </p>
                  </div>
                  <div className="headerIcon">
                    <FontAwesomeIcon
                      style={{ height: "100px", opacity: "0.2" }}
                      icon={faRoute}
                    />
                  </div>
                </div>
              </Carousel>
            </div>
          ) : null}{" "}
        </>
      ) : (
        <>
          {cheapest.length || fastest.length || best.length ? (
            <div className="mainBox">
              <div
                ref={f1}
                style={{ display: "flex", justifyContent: "space-between" }}
                className="sortBox"
                onClick={() => {
                  handleClick("f1");
                }}
              >
                <div>
                  <h3 className="mb-0"> {t("FareList.SortCard.Cheapest")} </h3>
                  <h5 className="mb-0">
                    {currencyFormatter(cheapest[0]?.currency)}{" "}
                    {
                      LocalizationConverter(
                        B2CSettings.priceOption === "pricePerPerson"
                          ? (cheapest[0]?.totalPrice / TotalPassengers)?.toFixed(2)
                          : cheapest[0]?.totalPrice?.toFixed(2),
                        i18n.language
                      )
                    }
                  </h5>
                  <p className="mb-0">
                    {" "}
                    {cheapest[0]?.time.addHours}h {cheapest[0]?.time.minutes}m
                  </p>
                </div>
                <div className="headerIcon">
                  <FontAwesomeIcon
                    style={{ height: "100px", opacity: "0.2", fontSize: "40px" }}
                    icon={faDollar}
                  />
                </div>
              </div>
              <div
                ref={f3}
                style={{ display: "flex", justifyContent: "space-between" }}
                className="sortBox"
                onClick={() => {
                  handleClick("f3");
                }}
              >
                <div>
                  <h3 className="mb-0"> {t("FareList.SortCard.Best")}</h3>
                  <h5 className="mb-0">
                    {currencyFormatter(best[0]?.currency)}{" "}

                    {
                      LocalizationConverter(
                        B2CSettings.priceOption === "pricePerPerson"
                          ? (best[0]?.totalPrice / TotalPassengers)?.toFixed(2)
                          : best[0]?.totalPrice?.toFixed(2),
                        i18n.language
                      )
                    }

                  </h5>
                  <p className="mb-0">
                    {" "}
                    {best[0]?.time.addHours}h {best[0]?.time.minutes}m{" "}
                  </p>
                </div>
                <div className="headerIcon">
                  <FontAwesomeIcon
                    style={{ height: "100px", opacity: "0.2" }}
                    icon={faHandHoldingDollar}
                  />
                </div>
              </div>
              <div
                ref={f2}
                style={{ display: "flex", justifyContent: "space-between" }}
                className="sortBox"
                onClick={() => {
                  handleClick("f2");
                }}
              >
                <div>
                  <h3 className="mb-0">{t("FareList.SortCard.Fastest")} </h3>
                  <h5 className="mb-0">
                    {currencyFormatter(fastest[0]?.currency)}{" "}
                    {
                      LocalizationConverter(
                        B2CSettings.priceOption === "pricePerPerson"
                          ? (fastest[0]?.totalPrice / TotalPassengers)?.toFixed(2)
                          : fastest[0]?.totalPrice?.toFixed(2),
                        i18n.language
                      )
                    }
                  </h5>
                  <p className="mb-0">
                    {" "}
                    {fastest[0]?.time.addHours}h {fastest[0]?.time.minutes}m{" "}
                  </p>
                  {/* <p> {Fastest[0]?.time[0].d}d {Fastest[0]?.time[0].h}h {Fastest[0]?.time[0].m}m ({Fastest[0]?.travelStopps[0].travelStop} Stops overall)</p> */}
                </div>
                <div className="headerIcon">
                  <FontAwesomeIcon
                    style={{ height: "100px", opacity: "0.2" }}
                    icon={faClock}
                  />
                </div>
              </div>
              <div
                ref={f4}
                style={{ display: "flex", justifyContent: "space-between" }}
                className="sortBox"
                onClick={() => {
                  handleClick("f4");
                }}
              >
                <div>
                  <h3 className="mb-0">{t("FareList.SortCard.Shortest")} </h3>
                  <h5 className="mb-0">
                    {currencyFormatter(short[0]?.currency)}{" "}
                    {
                      LocalizationConverter(
                        B2CSettings.priceOption === "pricePerPerson"
                          ? (short[0]?.totalPrice / TotalPassengers)?.toFixed(2)
                          : short[0]?.totalPrice?.toFixed(2),
                        i18n.language
                      )
                    }
                  </h5>
                  <p className="mb-0">
                    {" "}
                    {short[0]?.time.addHours}h {short[0]?.time.minutes}m{" "}
                  </p>
                </div>
                <div className="headerIcon">
                  <FontAwesomeIcon
                    style={{ height: "100px", opacity: "0.2" }}
                    icon={faRoute}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default SortFunction;
