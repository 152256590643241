import { faMedium } from "@fortawesome/free-brands-svg-icons";
import {
    faChalkboardTeacher,
    faCircle,
    faCoffee,
    faCookieBite,
    faCouch,
    faExchange,
    faFastForward,
    faGavel,
    faGlassMartini,
    faInfoCircle,
    faMapMarkerQuestion,
    faMapSigns,
    faMoneyCheckEditAlt,
    faPaw,
    faPlug,
    faRandom,
    faRedo,
    faShoppingBag,
    faSuitcase,
    faTachometerFastest,
    faTicketAlt,
    faTv,
    faUndoAlt,
    faUserCheck,
    faUtensils,
    faWifi,
    IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import _ from "lodash";
import {
    IncludedServiceData,
    IncludedServiceMainType,
    IncludedServiceSubType,
} from "WebApiClient";
import Tooltip from "@mui/material/Tooltip";
import { SabreIconsEmoji } from "components/Fare/IncludedServiceIcons/SabreIcons";
import { useSelector } from "react-redux";
import { State } from 'rootExports/rootReducer';

interface IncludedServiceIconProps {
    data: any;
    incSerFull?: any;
    toShow?: boolean;
    checkSabreOther?: any;
    iconDataDefaultHeight: number;
    fullToolTip: boolean;
    includedServices?: any;
}

interface IconEntry {
    icon: string; // Could be a string (for emojis) or an import (for image paths)
    hint: string; // Description or hint associated with the icon
}

var warningCheck = false;

export const IncServices: React.FC<IncludedServiceIconProps> = (props) => {
    const iconDataDefaultHeight = props.iconDataDefaultHeight;
    var mainGroup: any = props.data;
    const uniqueServices = _.uniqBy(mainGroup.services, "hint");
    const resultObject = { ...mainGroup, services: uniqueServices };
    const iconData = useSelector((state: State) => state.Session.FrontendSettings.iconDataSettings);
    const sabreIcons:Record<string, IconEntry> = SabreIconsEmoji(iconData);
    
    const checkSabreServiceIcon = () => {
        switch (props.data.type) {
            case 0:
                return (
                    <img
                        src={sabreIcons['Carry-on baggage']?.icon || ""}
                        height={`${iconDataDefaultHeight}px`}
                        alt="Carry-on baggage"
                    />
                );
            case 1:
                return (
                    <img
                        src={sabreIcons.Seat?.icon || ""}
                        height={`${iconDataDefaultHeight}px`}
                        alt="Seat"
                    />
                );
            case 2:
                return (
                    <img
                        src={sabreIcons.M?.icon || ""}
                        height={`${iconDataDefaultHeight}px`}
                        alt="M"
                    />
                );
            case 3:
                return (
                    <img
                        src={sabreIcons.MileageAccural?.icon || ""}
                        height={`${iconDataDefaultHeight}px`}
                        alt="Mileage Accrual"
                    />
                );
            case 5:
                return (
                    <img
                        src={sabreIcons.InFlightEntertainment?.icon || ""}
                        height={`${iconDataDefaultHeight}px`}
                        alt="In-Flight Entertainment"
                    />
                );
            case 6:
                return (
                    <img
                        src={sabreIcons.Change?.icon || ""}
                        height={`${iconDataDefaultHeight}px`}
                        alt="Change"
                    />
                );
            default:
                return (
                    <FontAwesomeIcon
                        icon={GetIcon(mainGroup)}
                        style={{ marginRight: "4px" }}
                    />
                );
        }
    };

    const warningCheckFalse = (hint: string) => {
        warningCheck = false
        return hint
    }

    // Extract unique hints
    const combinedHints = Array.from(
        new Map(
            props.includedServices!
                .flatMap((item: any) => item.services!.map((service: any) => ({ hint: service.hint })))
                .map((obj: any) => [obj.hint, obj]) // Use the hint as the key
        ).values()
    );

    return (
        <React.Fragment>
            <Tooltip title={
                <React.Fragment>
                    {
                        combinedHints.length && combinedHints!.map((sub: any, subIndex: any) => <span key={"mainSer_" + subIndex}>
                            <li>
                                {sub.hint}
                            </li>
                        </span>
                        )}
                </React.Fragment>
            }>

                <span>
                    {
                        <span>
                            {resultObject.services!.map((sub: any, Index: any) => (
                                <>
                                    {
                                        Index == 0 && <span style={{ marginRight: "4px" }}>
                                            {typeof sub.hint === "string" &&
                                                sub.hint.includes("WARNING:") && props?.toShow
                                                ? null : checkSabreServiceIcon()}
                                        </span>
                                    }
                                </>

                            ))}
                        </span>
                    }
                    {props.toShow &&
                        resultObject.services!.map((sub: any, Index: any) => (
                            <span style={{ marginRight: "4px" }}>
                                {typeof sub.hint === "string" &&
                                    sub.hint.includes("WARNING:")
                                    ? null
                                    : warningCheckFalse(sub.hint)}
                                {Index + 1 !== resultObject.services.length ? (
                                    <span>,</span>
                                ) : (
                                    <> </>
                                )}
                            </span>
                        ))}
                </span>

            </Tooltip>
        </React.Fragment>
    );
};

function GetIcon(data: IncludedServiceData): IconDefinition {
    let iconClass: IconDefinition = faInfoCircle;
    switch (data.subType) {
        case IncludedServiceSubType.FreeBaggageAllowance:
            iconClass = faSuitcase;
            break;
        case IncludedServiceSubType.CarryOnBaggage:
            iconClass = faShoppingBag;
            break;
        case IncludedServiceSubType.Lunch:
            iconClass = faUtensils;
            break;
        case IncludedServiceSubType.Snack:
            iconClass = faCookieBite;
            break;
        case IncludedServiceSubType.SnacksAndBeverage:
            iconClass = faUtensils;
            break;
        case IncludedServiceSubType.NonAlcoholicDrink:
            iconClass = faCoffee;
            break;
        case IncludedServiceSubType.FreeAlcoholicDrink:
            iconClass = faGlassMartini;
            break;
        // INFLIGHT ENTERTAINMENT
        case IncludedServiceSubType.PersonalEntertainment:
            iconClass = faTv;
            break;
        case IncludedServiceSubType.InternetAccess:
            iconClass = faWifi;
            break;
        case IncludedServiceSubType.InSeatpower:
            iconClass = faPlug;

            break;
        case IncludedServiceSubType.Pet:
            iconClass = faPaw;

            break;
        case IncludedServiceSubType.Security:
            iconClass = faGavel;

            break;
        case IncludedServiceSubType.PriorityBoarding:
            iconClass = faFastForward;

            break;
        case IncludedServiceSubType.Ticket:
            iconClass = faTicketAlt;

            break;
        case IncludedServiceSubType.CheckIn:
            iconClass = faChalkboardTeacher;

            break;
        case IncludedServiceSubType.ReBooking:
            iconClass = faRedo;
            break;
        case IncludedServiceSubType.RefundAllowed:
            iconClass = faMoneyCheckEditAlt;
            break;
        case IncludedServiceSubType.PriorityCheckIn:
            iconClass = faUserCheck;
            break;
        case IncludedServiceSubType.FastTrack:
            iconClass = faTachometerFastest;
            break;
        case IncludedServiceSubType.ChangeAfterDeparture:
            iconClass = faUndoAlt;
            break;
        case IncludedServiceSubType.ChangeBeforeDeparture:
            iconClass = faUndoAlt;
            break;
        case IncludedServiceSubType.ChangeInCaseOfNoShow:
            iconClass = faMapMarkerQuestion;
            break;
        case IncludedServiceSubType.OpenReturnDate:
            iconClass = faExchange;
            break;
        case IncludedServiceSubType.Stopover:
            iconClass = faMapSigns;
            break;
        default:
            switch (data.type) {
                case IncludedServiceMainType.Baggage:
                    iconClass = faSuitcase;
                    break;
                case IncludedServiceMainType.Meal:
                    iconClass = faUtensils;
                    break;
                case IncludedServiceMainType.InFlightEntertainment:
                    iconClass = faTv;
                    break;
                case IncludedServiceMainType.Miles:
                    iconClass = faMedium;
                    break;
                case IncludedServiceMainType.Transfer:
                    iconClass = faRandom;

                    break;
                case IncludedServiceMainType.Seat:
                    iconClass = faCouch;

                    break;
                case IncludedServiceMainType.Other:
                    iconClass = faCircle;
                    break;
            }
    }
    return iconClass;
}
export default IncServices;
