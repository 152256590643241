import React, { CSSProperties, useEffect, useRef, useState } from "react";
import {
  Airline,
  Airport,
  ApiCredentialsOptions,
  AvailableFareConnectionModel,
  AvailableFareModel,
  InludedServiceMainGroup,
  PublicServicesClient,
} from "WebApiClient";
import FareLegConnectionTableRow from "./Fare.Leg.ConTbl.Row";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import IncServices from "components/Shared/IncServices";
import { AgentApiURL } from "Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";
import { faLuggageCart } from "@fortawesome/pro-solid-svg-icons";
import Warning from "components/Shared/Warning";
import { useSelector } from "react-redux";
import { State } from 'rootExports/rootReducer';
import { currencyFormatter } from "components/Shared/Helpers/CurrencyConverter";
import { capitalizeWholeHint, getFirstTwoLettersCapitalized, processBrandNames } from "components/Shared/Helpers/TextCapitalizer";
import { GetIcon } from "components/Shared/IncludedServiceIcon";
interface ConnectionTableProps {
  Data: AvailableFareConnectionModel[];
  SelectedConnection: any;
  GetSelectedConnectionIndexByLegIndex: any;
  LegIndex: number;
  SelectConnection: (legIndex: number, connectionIndex: number) => void;
  IsWebFare: boolean;
  ApplicableConnections: number[] | undefined;
  Fare: AvailableFareModel;
  radio: boolean;
  showSelected: boolean;
  upsellToVisible: boolean;
}

interface AirportData {
  iata: string;
  countryCode: string;
  cityName: string;
}

const FareLegConnectionTable: React.FC<ConnectionTableProps> = (props) => {
  const iconDataDefaultHeight: number = useSelector((state: State) => state.Session.FrontendSettings.iconDataSettings[0].defaultHeight)
  const { t } = useTranslation();
  function HasAllotment() {
    let result = false;
    props.Data.forEach((con) => {
      con.segments.forEach((seg) => {
        if (seg.allotmentInformation) {
          result = true;
        }
      });
    });
    return result;
  }

  // Function to check equality for all objects in the array
  const areAllIncludedServicesEqual = (
    dataArray: any,
    stringToSearch: string
  ) => {
    const firstObject = dataArray[0];
    if (dataArray.length < 2) {
      const segments1 = _.get(firstObject, "segments", []);
      if (segments1?.length < 2) {
        return true;
      }
      for (let j = 0; j < segments1.length; j++) {
        const singleCellDoubleBaggage = areIncludedServicesEqual(
          segments1[j],
          segments1[j + 1],
          stringToSearch
        );
        if (singleCellDoubleBaggage) {
          return true;
        }
      }
    }
    if (dataArray.length > 1) {
      for (let i = 1; i < dataArray.length; i++) {
        const currentObject = dataArray[i];

        // Get the segments array from each object
        const segments1 = _.get(firstObject, "segments", []);
        const segments2 = _.get(currentObject, "segments", []);

        // Check if the lengths of segments arrays are equal
        if (segments1.length !== segments2.length) {
          return false;
        }

        // Check the equality of includedServices for each segment
        for (let j = 0; j < segments1.length; j++) {
          const includedServicesEqual = areIncludedServicesEqual(
            segments1[j],
            segments2[j],
            stringToSearch
          );
          if (!includedServicesEqual) {
            return false;
          }
        }
      }
      return true;
    }
  };

  // Function to check if the arrays of includedServices are equal
  const areIncludedServicesEqual = (
    obj1: any,
    obj2: any,
    searchString: string
  ) => {
    const services1 = _.get(obj1, searchString, []);
    const services2 = _.get(obj2, searchString, []);
    if (searchString == "cabinClassName" && services1 !== services2) {
      return false;
    }
    if (
      (typeof services1 !== "string" || typeof services2 !== "string") &&
      (services1.length == 0 || services2.length == 0)
    ) {
      return true;
    }
    if (
      (typeof services1 === "string" || typeof services2 === "string") &&
      (services1 == "" || services2 == "")
    ) {
      return true;
    }
    return _.isEqual(services1, services2);
  };

  // Check if the includedServices are equal for all objects in the array
  const resultIsPresentAll = areAllIncludedServicesEqual(
    props.Data,
    "includedServices"
  );

  const baggageIsPresentAll = areAllIncludedServicesEqual(
    props.Data,
    "freeBaggageAllowance"
  );

  const cabinIsPresentAll = areAllIncludedServicesEqual(
    props.Data,
    "cabinClassName"
  );

  //If more than one class cabin present Checking here
  // Flatten the array and use uniqBy to check if "cabinClassName" values are different
  const areCabinClassNamesDifferent =
    _.uniqBy(
      _.flatMap(props.Fare.legs, "connections[0].segments"),
      "cabinClassName"
    ).length > 1;

  // Assuming your array of objects is named 'combinedSegments'
  const combinedSegments = _.flatMap(props.Fare.legs, "connections").map(
    (connection) => connection.segments
  );

  // Check if all includedServices arrays and freeBaggageAllowance properties are the same or all are empty
  const areIncludedServicesBaggageEqualsFinal = _.every(
    combinedSegments,
    (objArray, index) =>
      _.isEqualWith(
        objArray[0]?.includedServices,
        combinedSegments[0][0]?.includedServices,
        (a, b) => _.isEqual(a, b) || (_.isEmpty(a) && _.isEmpty(b))
      ) &&
      _.isEqual(
        combinedSegments[index][0]?.freeBaggageAllowance,
        combinedSegments[0][0]?.freeBaggageAllowance
      )
  );

  const filteredSegments = props.Data.sort((a, b) => {
    const legTravelTimeA = a.connectionHeader
      ? a.connectionHeader.legTravelTime.hours * 60 +
      a.connectionHeader.legTravelTime.minutes
      : Infinity;
    const legTravelTimeB = b.connectionHeader
      ? b.connectionHeader.legTravelTime.hours * 60 +
      b.connectionHeader.legTravelTime.minutes
      : Infinity;
    return legTravelTimeA - legTravelTimeB;
  });
  const [isWarning, setIsWarning] = useState(true);
  //check if codeShare is not null;
  // const doesCodeShareHaveValue = _.some(_.flatMap(props.Fare.legs, 'connections[0].segments'), 'codeShare');

  function hasNonEmptyMealCode(filteredSegment: any) {
    return filteredSegment.some((meal: any) => meal.mealCode !== "");
  }
  // console.log('checkkkk - ',props.Fare.legs[0].fareTypeInformation.originalFareType,props.Fare.legs[0].fareTypeInformation.salesPreferenceDisplayName)
  const emptyMealCode = hasNonEmptyMealCode(filteredSegments);

  const isSabreAmadeusPresent = props.Fare.legs[0].fareTypeInformation.originalFareType == "Sabre" ||
    props.Fare.legs[0].fareTypeInformation.salesPreferenceDisplayName == "Sabre" ||
    props.Fare.legs[0].fareTypeInformation.originalFareType == "Amadeus" ||
    props.Fare.legs[0].fareTypeInformation.salesPreferenceDisplayName == "Amadeus" ||
    props.Fare.legs[0].fareTypeInformation.salesPreferenceDisplayName == "1S" ||
    props.Fare.legs[0].fareTypeInformation.originalFareType == "1S";

  const getUniqueBrandNames = (array: any) => {
    const uniqueBrandNames = new Set();
    const uniqueBrandNameObjects: any = [];

    array.forEach((item: any) => {
      if (!uniqueBrandNames.has(item.brandName)) {
        uniqueBrandNames.add(item.brandName);
        uniqueBrandNameObjects.push(item);
      }
    });

    return uniqueBrandNameObjects;
  };

  //remove duplicate services of included services 
  const removeDuplicateServices = (dataArray: InludedServiceMainGroup[]) => {
    return dataArray.map(item => {
      const uniqueServices = _.uniqWith(item.services, (a, b) =>
        a.type === b.type && a.subType === b.subType && a.hint === b.hint
      );

      return {
        ...item,
        services: uniqueServices
      };
    });
  };

  const getUniqueIncludedServices = (data: any) => {
    const uniqueServicesMap = new Map();

    data.forEach((item: any) => {
      item.includedServices.forEach((service: any) => {
        const serviceString = JSON.stringify(service);
        if (!uniqueServicesMap.has(serviceString)) {
          uniqueServicesMap.set(serviceString, service);
        }
      });
    });

    return Array.from(uniqueServicesMap.values());
  };

  const segments = props.Data[props.SelectedConnection].segments;
  // Check if any segment has `brandName` as "Economy Green"
  const isEconomyGreen = segments.some((segment: any) => segment?.brandName === "Economy Green");

  return (
    <React.Fragment>
      {props.upsellToVisible ? (
        <div className="upSellContent">
          <div className="upSellHeader" style={{
            background: isEconomyGreen
              ? "#4CAF50"
              : ""
          }}>
            <p>
              <span className="ancillaries text-s">
                {processBrandNames(getUniqueBrandNames(props.Data[props.SelectedConnection].segments)).map((segment: any, index: any) => (
                  <span key={"props.Data.segments7" + index}>
                    <span>{segment?.brandName}</span>
                  </span>
                ))}
              </span></p>
            <p>
              <span className="ancillaries text-s">
                {props.Fare.totalPrice.toFixed(2)}
                {currencyFormatter(props.Fare.currency)}
              </span>
              <span className="ancillaries text-s" style={{ fontSize: '10px', fontWeight: '500', float: 'right', marginTop: '5px' }}>
                {getFirstTwoLettersCapitalized(props.Fare.fareInfoBox.queryGDS)}
              </span>
            </p>
          </div>
          <div className="hoverUpsellIcon">
            <span>
              <React.Fragment>
                {removeDuplicateServices(getUniqueIncludedServices(props.Data[props.SelectedConnection].segments)).map(
                  (mainGroup: any, mainGroupIndex) => (
                    <span style={{ display: 'flex', alignItems: 'self-start' }}>
                      <span style={{ minWidth: '16px', marginRight: '4px', display: 'flex', alignItems: 'start', paddingTop: '4px' }}>
                        <FontAwesomeIcon
                          className="upsellIcon"
                          icon={GetIcon(mainGroup)}
                        />
                      </span>
                      <div style={{ lineHeight: '16px' }}>
                        {
                          capitalizeWholeHint(mainGroup.services)!.map((sub: any, subIndex: any) => <span key={"mainSer_" + subIndex}>
                            <span style={{ fontSize: '10px' }}>
                              {sub.hint}
                            </span>
                            <br />
                          </span>
                          )}
                      </div>
                    </span>
                  )
                )}
              </React.Fragment>
            </span>
          </div>
        </div>
      ) : (
        <>
          {props.LegIndex === 0 && areIncludedServicesBaggageEqualsFinal && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {baggageIsPresentAll && (
                <div>
                  <span>
                    {!props?.Data[props.SelectedConnection]?.segments[0].freeBaggageAllowance ? (
                      <span>{t('FareList.FareLegCntTbl.NoBaggage')}</span>
                    ) : (
                      <>
                        {props?.Data[props.SelectedConnection]?.segments[0].freeBaggageAllowance.length > 0
                          ? `Baggage - ${props?.Data[props.SelectedConnection]?.segments[0].freeBaggageAllowance}`
                          : t('FareList.FareLegCntTbl.NoBaggage')}
                      </>
                    )}
                  </span>
                  <br />
                </div>
              )}
              {resultIsPresentAll && !isSabreAmadeusPresent && (
                <>
                  <div>
                    <span>
                      {props?.Data[props.SelectedConnection]?.segments[0].includedServices.length ? (
                        <>
                          {t('FareList.FareLegCntTbl.Incl.Services')}&nbsp;
                        </>
                      ) : null}
                    </span>
                    <span className="ancillaries text-s">
                      <span>
                        {props?.Data[props.SelectedConnection]?.segments[0].includedServices.length ? (
                          <>
                            {props?.Data[props.SelectedConnection]?.segments[0].includedServices.map(
                              (mainGroup, mainGroupIndex) => (
                                <IncServices
                                  key={`mainGroup_${mainGroupIndex}`}
                                  data={mainGroup}
                                  incSerFull={props?.Data[props.SelectedConnection]?.segments[0].includedServices}
                                  toShow
                                  iconDataDefaultHeight={iconDataDefaultHeight}
                                  fullToolTip={false}
                                  includedServices={props?.Data[props.SelectedConnection]?.segments[0].includedServices}
                                />
                              )
                            )}
                          </>
                        ) : null}
                      </span>
                    </span>
                  </div>
                  {isWarning && (
                    <div className={props?.Data[props.SelectedConnection]?.segments[0].includedServices.length ? 'mt-3 mb-1' : ''}>
                      <span className={props?.Data[props.SelectedConnection]?.segments[0].includedServices.length ? 'isWarning pt-2 ' : ''}>
                        {props?.Data[props.SelectedConnection]?.segments[0].includedServices.length ? (
                          <>
                            {props?.Data[props.SelectedConnection]?.segments[0].includedServices.map(
                              (mainGroup, mainGroupIndex) => (
                                <Warning
                                  key={`mainGroup_${mainGroupIndex}`}
                                  data={mainGroup}
                                  incSerFull={props?.Data[props.SelectedConnection]?.segments[0].includedServices}
                                  setIsWarning={setIsWarning}
                                  toShow
                                />
                              )
                            )}
                          </>
                        ) : null}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {!areIncludedServicesBaggageEqualsFinal && (
            <div className="mb-2" style={{ display: 'flex', flexDirection: 'column' }}>
              {resultIsPresentAll && (
                <div>
                  <span>
                    {props?.Data[props.SelectedConnection]?.segments[0].includedServices.length ? (
                      <>
                        {t('FareList.FareLegCntTbl.Incl.Services')}&nbsp;
                      </>
                    ) : (
                      <></>
                    )}
                  </span>
                  <span className="ancillaries text-s">
                    <span>
                      {props?.Data[props.SelectedConnection]?.segments[0].includedServices.length ? (
                        <>
                          {props?.Data[props.SelectedConnection]?.segments[0].includedServices.map(
                            (mainGroup, mainGroupIndex) => (
                              <IncServices
                                key={`mainGroup_${mainGroupIndex}`}
                                data={mainGroup}
                                incSerFull={props?.Data[props.SelectedConnection]?.segments[0].includedServices}
                                toShow
                                iconDataDefaultHeight={iconDataDefaultHeight}
                                fullToolTip={false}
                                includedServices={props?.Data[props.SelectedConnection]?.segments[0].includedServices}
                              />
                            )
                          )}
                        </>
                      ) : null}
                    </span>
                    <br />
                  </span>
                </div>
              )}
              {baggageIsPresentAll && (
                <div className="mb-2">
                  <span>
                    {!props?.Data[props.SelectedConnection]?.segments[0].freeBaggageAllowance ? (
                      <span>{t('FareList.FareLegCntTbl.NoBaggage')}</span>
                    ) : (
                      <>
                        {props?.Data[props.SelectedConnection]?.segments[0].freeBaggageAllowance.length > 0
                          ? `Baggage - ${props?.Data[props.SelectedConnection]?.segments[0].freeBaggageAllowance}`
                          : t('FareList.FareLegCntTbl.NoBaggage')}
                      </>
                    )}
                  </span>
                  <br />
                </div>
              )}
            </div>
          )}

          <div style={{ overflow: 'auto' }} className="multi-collapse segment__moreFlightTimes">
            <table className="table table-sm table-bordered table-responsive-xl">
              <thead className="thead-light">
                <tr className="fareTableHeaderTR">
                  {props.radio && !props.showSelected && <th style={{ width: '1%' }}></th>}
                  <th className="fareTabletrtdRouting">{t('FareList.FareLegCntTbl.Routing')}</th>
                  {(!cabinIsPresentAll || areCabinClassNamesDifferent) && (
                    <th className="fareTabletrtdCabin">{t('FareList.FareLegCntTbl.Cabin')}</th>
                  )}
                  <th className="fareTabletrtdFlight">{t('FareList.FareLegCntTbl.Flight')}</th>
                  <th className="fareTabletrtdDate">{t('FareList.FareLegCntTbl.Date')}</th>
                  <th className="fareTabletrtdTime">{t('FareList.FareLegCntTbl.Time')}</th>
                  <th className="fareTabletrtdType">{t('FareList.FareLegCntTbl.Type')}</th>
                  {!baggageIsPresentAll && (
                    <th className="fareTabletrtdBaggage">{t('FareList.FareLegCntTbl.Baggage')}</th>
                  )}
                  {!resultIsPresentAll && (
                    <th className="fareTabletrtdServices">{t('FareList.FareLegCntTbl.Incl.Services')}</th>
                  )}
                  {emptyMealCode && <th className="fareTabletrtdMeal">Meal</th>}
                  {HasAllotment() && <th>A</th>}
                </tr>
              </thead>
              <tbody>
                {filteredSegments.map((connection, index) => (
                  <FareLegConnectionTableRow
                    key={`FareLegConnectionTableRows1${index}`}
                    radio={props.radio}
                    Data={connection!}
                    SelectedConnection={props.SelectedConnection}
                    GetSelectedConnectionIndexByLegIndex={props.GetSelectedConnectionIndexByLegIndex}
                    connection={filteredSegments}
                    LegIndex={props.LegIndex}
                    ConnectionIndex={index}
                    SelectConnection={props.SelectConnection}
                    IsWebFare={props.IsWebFare}
                    connectionAppliesToFilter={props.ApplicableConnections ? props.ApplicableConnections.includes(index) : true}
                    Fare={props.Fare}
                    resultIsPresentAllIncl={resultIsPresentAll}
                    baggageIsPresentAll={baggageIsPresentAll}
                    cabinIsPresentAll={cabinIsPresentAll}
                    isCabinVisibleInTable={areCabinClassNamesDifferent}
                    showSelected={props.showSelected}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default FareLegConnectionTable;