import { faBug, faEllipsisV, faPlaneArrival, faPlaneDeparture, faSearch, faSync } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { BugReportModal } from "components/Shared/BugReportModal";
import { DateDisplay, DateTimeDisplay, TimeDisplay } from "components/Shared/DateDisplay";
import IncServices from "components/Shared/IncServices"
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useAvailableFaresClient } from "hooks/useHttpClient";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { AvailableFareLegModel, AvailableFareModel, AvailableFareSegmentAddionalInformationModel, AvailableFareSegmentModel, DetailedAvailableFareLeg, DetailedAvailableFareTicketgroupModel, DetailedAvailableFareTicketGroupsModel, FareIdentifier, FlightDetailsApiResponse } from "WebApiClient";
import { AllotmentInfoTable } from "./Fare.AllotmentInfo";
import FareLeg from "./Fare.Leg";
import { State } from 'rootExports/rootReducer';
interface FlightDetailsModalProps {
    IsOpen: boolean;
    Toggle: () => void;
    Identifier: FareIdentifier
    SelectedConnections: number[];
    SetSelectedConnection: (legindex: number, connectionIndex: number) => void
    Fare: AvailableFareModel
    GetSelectedConnectionIndexByLegIndex: any;
    testConnection: any;
}

export const FlightDetailsModal: React.FC<FlightDetailsModalProps> = props => {
    const dispatch = useDispatch();
    const client = useAvailableFaresClient();
    const [response, setResponse] = useState<FlightDetailsApiResponse | undefined>(undefined);
    const responseMetaData = response?.responseMetaData;
    const result: DetailedAvailableFareTicketGroupsModel | undefined = response?.result;
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(false);
    const [bugReportModalOpen, setBugReportOpen] = useState(false);
    const ToggleBugReportModal = () => { setBugReportOpen(!bugReportModalOpen) };

    function OnRefresh() {
        Fetch(props.SelectedConnections);
    }

    function Fetch(selectedConnections: number[]) {
        setFetching(true);
        setResponse(undefined);
        setError(false);
        client.getFlightDetails({ fareIdentifier: props.Identifier, selectedLegConnections: selectedConnections }).then(response => {
            dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
            setResponse(response);
            if (response.result) {
                setError(false);
            }
            else {
                setError(true);
            }
        }).catch(() => {
            setError(true);
        }).finally(() => {
            setFetching(false)
        })
    }

    useEffect(() => {
        if (props.IsOpen) {
            Fetch(props.SelectedConnections);
        }
    }, [props.IsOpen]);

    return (
        <Modal size="xl" scrollable={true} isOpen={props.IsOpen} centered={true} toggle={props.Toggle}>
            <ModalHeader toggle={props.Toggle}>Flight details</ModalHeader>
            <ModalBody>
                {props.IsOpen &&
                    <div className="row">
                        {fetching ?
                            <div className="col-12 mb-2">
                                <WaitingCard />
                            </div> :
                            <Fragment>

                                {error &&
                                    <Fragment>
                                        <div className="col-12">
                                            <ErrorDisplay isHide={true} data={response?.responseMetaData}/> 
                                        </div>
                                    </Fragment>
                                }
                                {result &&
                                    <Fragment>
                                        <div className="col-12 mb-2">
                                            <FlightDetailsResultComponent data={result} Fare={props.Fare} SetSelectedConnection={props.SetSelectedConnection} GetSelectedConnectionIndexByLegIndex={props.GetSelectedConnectionIndexByLegIndex} testConnection={props.testConnection} />
                                        </div>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </div>
                }
            </ModalBody>
            <ModalFooter>
                {/* <Button color="primary" onClick={FetchRules}>Refresh</Button>{' '} */}
                {responseMetaData &&
                    <button type="button" className="btn btn-secondary" onClick={ToggleBugReportModal}><FontAwesomeIcon icon={faBug} /> Bug Report</button>
                }
                {!fetching &&
                    <button type="button" className="btn btn-secondary" onClick={OnRefresh}><FontAwesomeIcon icon={faSync} /> Refresh</button>
                }
                <Button color="primary" onClick={props.Toggle}>close</Button>
            </ModalFooter>
            <BugReportModal Toggle={ToggleBugReportModal} IsOpen={bugReportModalOpen} MetaData={responseMetaData}></BugReportModal>
        </Modal>
    );
};





const FlightDetailsResultComponent: React.FC<{ data: DetailedAvailableFareTicketGroupsModel, Fare: AvailableFareModel, GetSelectedConnectionIndexByLegIndex: any, testConnection: any, SetSelectedConnection: (legindex: number, connectionIndex: number) => void; }> = props => {
    const { detailedTicketGroups, totalTravelTime } = props.data;
    const showTicketsGroup = detailedTicketGroups.length > 1;

    return (
        <div className="row">
            {detailedTicketGroups.map((e, i) => <div className="col-12 mb-2" key={"detailedTicketGroups" + i}>
                <DetailedTicketGroupComponent data={e} showTicketGroup={showTicketsGroup} Fare={props.Fare} key={"DetailedTicketGroupComponent" + i} SetSelectedConnection={props.SetSelectedConnection} GetSelectedConnectionIndexByLegIndex={props.GetSelectedConnectionIndexByLegIndex} testConnection={props.testConnection} />
            </div>)}
        </div>
    )
};

const DetailedTicketGroupComponent: React.FC<{ data: DetailedAvailableFareTicketgroupModel, showTicketGroup: boolean, Fare: AvailableFareModel, GetSelectedConnectionIndexByLegIndex: any, testConnection: any, SetSelectedConnection: (legindex: number, connectionIndex: number) => void; }> = ({ showTicketGroup, data, Fare, SetSelectedConnection, GetSelectedConnectionIndexByLegIndex, testConnection }) => {
    const { errorMessage, legs, ticketGroupID, totalTravelTime, transactionID } = data;
    return <div className="card">
        {showTicketGroup &&
            <div className="card-header">Ticketgroup {ticketGroupID}</div>
        }
        <div className="card-body card-body-primary">
            <div className="row">
                {legs.map((e, i) =>
                    <Fragment key={"Fragment" + i}>
                        {i !== 0 &&
                            <div className="col-12 mb-2 text-center">
                                <FontAwesomeIcon size="2x" icon={faEllipsisV} />
                            </div>
                        }
                        <div className="col-12 mb-2">
                            <DetailedTicketGroupLegComponent data={e} Fare={Fare} SetSelectedConnection={SetSelectedConnection} GetSelectedConnectionIndexByLegIndex={GetSelectedConnectionIndexByLegIndex} tetconnection={testConnection(i)} />
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    </div>
}

const DetailedTicketGroupLegComponent: React.FC<{ data: DetailedAvailableFareLeg, Fare: AvailableFareModel, GetSelectedConnectionIndexByLegIndex: any, tetconnection: any, SetSelectedConnection: (legindex: number, connectionIndex: number) => void; }> = props => {
    const { header, segments, carrier, legIndex, selectedConnectionIndex } = props.data;
    const leg: AvailableFareLegModel | undefined = props.Fare.legs[legIndex];
    const [flightTimes, setflightTimes] = useState(false);
    const ToggleFlightTimes = () => { setflightTimes(!flightTimes) };

    return <div>
        <div className="card">
            <div className="card-body card-body-secondary">
                <div className="row">
                    <Fragment>
                        <div className="col-12">
                            <div className="row">
                                {leg &&
                                    <div className="col-12 mb-2">
                                        <FareLeg
                                            LegIndex={legIndex}
                                            // SelectedConnection={selectedConnectionIndex}
                                            Data={leg}
                                            key={"FareLegss" + legIndex}
                                            ShowFlightTimes={flightTimes}
                                            SelectConnection={props.SetSelectedConnection}
                                            ShowLegPlatingCarrier={true}
                                            Fare={props.Fare}
                                            ToggleFlightTimes={ToggleFlightTimes}
                                            GetSelectedConnectionIndexByLegIndex={props.GetSelectedConnectionIndexByLegIndex}
                                            SelectedConnection={props.tetconnection}
                                        />
                                    </div>
                                }
                                <div className="col-12 mb-2">
                                    <div className="flight__summary_box">
                                        {header ?
                                            <Fragment>
                                                <div className="row bg-mediumContrast">
                                                    <div className="col font-weight-bold text-center text-nowrap bb-1 p-1">Flight time</div>
                                                    <div className="col font-weight-bold text-center text-nowrap bb-1 p-1">Ground time</div>
                                                    <div className="col font-weight-bold text-center text-nowrap bb-1 p-1">Travel time</div>
                                                    <div className="col font-weight-bold text-center text-nowrap bb-1 p-1">Stops</div>
                                                </div>
                                                <div className="row bg-mediumContrast">

                                                    <div className="col text-center p-1">{header.flightTime}</div>
                                                    <div className="col text-center p-1">{header.groundTime}</div>
                                                    <div className="col text-center p-1">{header.travelTime}</div>
                                                    <div className="col text-center p-1">{header.travelStops}</div>

                                                </div>
                                            </Fragment> : <Fragment>Some additional information is not available due to the source of the fare.</Fragment>
                                        }
                                    </div>

                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">

                                    {segments.map((segment, segmentIndex) =>
                                        <Fragment key={"segments" + segmentIndex}>
                                            {segmentIndex !== 0 &&
                                                <div className="col-12 mb-2 text-center">
                                                    <FontAwesomeIcon size="2x" icon={faEllipsisV} />
                                                </div>
                                            }
                                            <div className="col-12 mb-2">
                                                <DetailedSegment segment={segment.baseSegment} additonals={segment.additionalInformation} />
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            </div>

                        </div>
                    </Fragment>
                </div>
            </div>

        </div>
    </div>
}

const DetailedSegment: React.FC<{ segment: AvailableFareSegmentModel, additonals?: AvailableFareSegmentAddionalInformationModel }> = ({ segment, additonals }) => {
    const iconDataDefaultHeight: number = useSelector((state: State) => state.Session.FrontendSettings.iconDataSettings[0].defaultHeight)
   
    const [open, setOpen] = useState(false);
    const { allotmentInformation, codeShare, departureDate, arrivalAirport, arrivalDate,
        arrivalTerminal, bookingClass, brandCode, brandName, cabinClassCode, cabinClassName,
        carrier, datePairs, dayIndicator, departureAirport, departureTerminal, equipmentCode,
        equipmentName, fareType, farebase, flightNumber, freeBaggageAllowance, freeBaggageDetails,
        freeSeats, hasFreeBaggage, includedServices, index, mctExceeded, mealCode, numberOfStops,
        securedFlight, segmentID, segmentMileage, subFareType, surcharge, totalSeats, travelTime } = segment;

    console.log("segment", segment);

    const Toggle = () => setOpen(!open);
    return <div className="card clickme" onClick={Toggle}>
        <div className="card-body">
            <div className="row">
                <div className="col-12 mb-2">
                    <div className="row">
                        <div className="col-12 mb-1">
                            <div className="row">
                                <div className="col-auto"></div>
                                <div className="col text-center">
                                    <img src={`/images/flags/${departureAirport.countryCode.toLowerCase()}.png`} alt="airport" /> {departureAirport.name} ({departureAirport.iata})
                                </div>
                                <div className="col-auto"></div>
                            </div>
                        </div>
                        <div className="col-12 mb-2">
                            <div className="row">
                                <div className="col-auto"></div>
                                <div className="col text-center">
                                    <FontAwesomeIcon icon={faPlaneDeparture} /> <DateTimeDisplay date={departureDate} />
                                </div>
                                <div className="col-auto"></div>
                            </div>
                        </div>

                        <div className="col-12 mb-2">
                            <div className="row">
                                <div className="col-auto"></div>
                                <div className="col text-center">
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                </div>
                                <div className="col-auto"></div>
                            </div>
                        </div>
                        <div className="col-12 mb-1">
                            <div className="row">
                                <div className="col-auto"></div>
                                <div className="col text-center">
                                    <img src={`/images/flags/${arrivalAirport.countryCode.toLowerCase()}.png`} alt="airport" /> {arrivalAirport.name} ({arrivalAirport.iata})
                                </div>
                                <div className="col-auto"></div>
                            </div>
                        </div>
                        <div className="col-12 mb-2">
                            <div className="row">
                                <div className="col-auto"></div>
                                <div className="col text-center">
                                    <FontAwesomeIcon icon={faPlaneArrival} /> <DateTimeDisplay date={arrivalDate} />
                                </div>
                                <div className="col-auto"></div>
                            </div>
                        </div>

                    </div>
                </div>
                {!open &&
                    <div className="col-12 text-center">
                        <span className="font-italic"><FontAwesomeIcon icon={faSearch} /> click to expand segment details</span>
                    </div>
                }
                <div className="col-12">
                    <Collapse isOpen={open}>
                        <Fragment>
                            {/* <div className="d-flex mt-3 mb-2 font-weight-bold modal__segment-header"><FontAwesomeIcon icon={faPlane} />{departureAirport.iata} - {arrivalAirport.iata}</div> */}
                            <div className="row bb-1">
                                <div className="col-4"></div>
                                <div className="col-4 font-weight-bold">Departure</div>
                                <div className="col-4 font-weight-bold">Arrival</div>
                            </div>
                            <div className="row bb-1">
                                <div className="col-4 font-weight-bold">City</div>
                                <div className="col-4">{departureAirport.cityName}</div>
                                <div className="col-4">{arrivalAirport.cityName}</div>
                            </div>
                            <div className="row bb-1">
                                <div className="col-4 font-weight-bold">Airport</div>
                                <div className="col-4">{departureAirport.name!} - ({departureAirport.iata!})</div>
                                <div className="col-4">{arrivalAirport.name!} - ({arrivalAirport.iata!})</div>
                            </div>
                            <div className="row bb-1">
                                <div className="col-4 font-weight-bold">Date</div>
                                <div className="col-4"><DateDisplay date={departureDate} /></div>
                                <div className="col-4"><DateDisplay date={arrivalDate} /></div>
                            </div>
                            <div className="row bb-1">
                                <div className="col-4 font-weight-bold">Time</div>
                                <div className="col-4"><TimeDisplay date={departureDate} /> </div>
                                <div className="col-4"><TimeDisplay date={arrivalDate} /></div>
                            </div>
                            <div className="row bb-1">
                                <div className="col-4 font-weight-bold">Terminal</div>
                                <div className="col-4">{departureTerminal}</div>
                                <div className="col-4">{arrivalTerminal}</div>
                            </div>
                            <div className="mt-3">
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Cabin class</div>
                                    <div className="col-8">{cabinClassName} ({cabinClassCode})</div>
                                </div>
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Booking class</div>
                                    <div className="col-8">{bookingClass}</div>
                                </div>
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Brand</div>
                                    <div className="col-8">{brandName} ({brandCode})</div>
                                </div>
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Equipment</div>
                                    <div className="col-8">{equipmentName} ({equipmentCode})</div>
                                </div>

                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Flight number</div>
                                    <div className="col-8">{flightNumber}</div>
                                </div>

                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Carrier</div>
                                    <div className="col-8">{carrier.code} - {carrier.hint}</div>
                                </div>
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Operated by</div>
                                    <div className="col-8">{codeShare ? <span>{codeShare.code} - {codeShare.hint}</span> : <span>Same as carrier</span>}</div>
                                </div>

                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Travel time</div>
                                    <div className="col-8">{travelTime}</div>
                                </div>
                                {additonals &&
                                    <Fragment>

                                        <div className="row bb-1">
                                            <div className="col-4 font-weight-bold">Days of operation</div>
                                            <div className="col-8">{additonals.daysOfOperation.join(" | ")}</div>

                                        </div>
                                        <div className="row bb-1">
                                            <div className="col-4 font-weight-bold">On time</div>
                                            <div className="col-8">{additonals.onTime}</div>

                                        </div>
                                    </Fragment>
                                }
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Meal</div>
                                    <div className="col-8">{mealCode}</div>
                                </div>
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Baggage</div>
                                    <div className="col-8">{freeBaggageAllowance} {freeBaggageDetails ? <span>Adult: {freeBaggageDetails.adult} | Child: {freeBaggageDetails.child} | Infant: {freeBaggageDetails.infant}</span> : ""}</div>
                                </div>
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Secured flight</div>
                                    <div className="col-8">{securedFlight ? securedFlight ? "Yes" : "No" : ""}</div>
                                </div>
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">MCT exceeded</div>
                                    <div className="col-8">{mctExceeded ? mctExceeded ? "Yes" : "No" : ""}</div>
                                </div>
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Fare type</div>
                                    <div className="col-8">{fareType}</div>
                                </div>
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Sub Fare type</div>
                                    <div className="col-8">{subFareType}</div>
                                </div>
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Fare base</div>
                                    <div className="col-8">{farebase}</div>
                                </div>
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Total seats</div>
                                    <div className="col-8">{totalSeats ? totalSeats.toString() : ""}</div>
                                </div>
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Free seats</div>
                                    <div className="col-8">{freeSeats ? freeSeats.toString() : ""}</div>
                                </div>
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Stops</div>
                                    <div className="col-8">{numberOfStops ? numberOfStops.toString() : ""}</div>
                                </div>
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Milage</div>
                                    <div className="col-8">{segmentMileage ? segmentMileage.toString() : ""}</div>
                                </div>
                                <div className="row bb-1">
                                    <div className="col-4 font-weight-bold">Surcharge</div>
                                    <div className="col-8"><SharedPriceDisplay price={surcharge} /></div>
                                </div>

                            </div>
                            {allotmentInformation &&
                                <div className="mt-3">
                                    <h6 className="font-weight-bold">Allotment</h6>
                                    <AllotmentInfoTable info={allotmentInformation} small={true} segment={segment} />
                                </div>
                            }
                            {datePairs.length > 0 &&
                                <div className="mt-3">
                                    <h6 className="font-weight-bold">Date pairs</h6>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Allotment ID</th>
                                                <th>Departure</th>
                                                <th>Arrival</th>
                                                <th>Booking class</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {datePairs.map((pair, index) =>
                                                <tr key={"pair" + index} className="row bb-1">
                                                    <td>{pair.allotmentID}</td>
                                                    <td><DateTimeDisplay date={pair.departureDate} /></td>
                                                    <td><DateTimeDisplay date={pair.arrivalDate} /></td>
                                                    <td>{pair.bookingClass}</td>
                                                </tr>

                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            }

                            {includedServices.length > 0 &&
                                <div className="mt-3">
                                    <h6 className="font-weight-bold">Included services</h6>
                                    {includedServices.map((mainGroup, mainIndex) =>
                                        <span className="ancillaries align-bottom" key={"main" + mainIndex}>
                                            <IncServices key={"mainIndex" + mainIndex} toShow={true} data={mainGroup} iconDataDefaultHeight={iconDataDefaultHeight} fullToolTip={false} includedServices={includedServices}/>
                                        </span>
                                    )}
                                </div>
                            }
                        </Fragment>


                    </Collapse>
                </div>
            </div>
        </div>

    </div>
}

